// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WhYW8TjLn_7nKZOuqZ0q{display:flex;align-items:center;justify-content:center;border-radius:.8rem;line-height:1.2rem;font-size:1.2rem;min-height:2.8rem;font-weight:600;width:fit-content;padding:.8rem 1.6rem .8rem 1.6rem;letter-spacing:.1rem;color:#393939;text-transform:uppercase}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/Pill/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,mBAAA,CACA,kBAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,iCAAA,CACA,oBAAA,CACA,aAAA,CACA,wBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.pill {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 0.8rem;\n    line-height: 1.2rem;\n    font-size: 1.2rem;\n    min-height: 2.8rem;\n    font-weight: 600;\n    width: fit-content;\n    padding: 0.8rem 1.6rem 0.8rem 1.6rem;\n    letter-spacing: 0.1rem;\n    color: rgba(57, 57, 57, 1);\n    text-transform: uppercase;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pill": `WhYW8TjLn_7nKZOuqZ0q`
};
export default ___CSS_LOADER_EXPORT___;
