import React, { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import SearchIcon from '@hero/ui-kit/icons/utility/SearchIcon';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import Form from '@hero/ui-kit/inputs/Form';
import Span from '@hero/ui-kit/typography/Span';

import SearchBar from './SearchBar';
import getSearchSchema from './validators';

export type SearchProps = {
    searchName?: string;
    placeholder?: string;
    searchLabel?: string;
    removeQueryParamsOnSearch?: string[];
};

const SearchForm: React.FC<SearchProps> = ({
    searchName = 'search',
    searchLabel = 'Search member',
    removeQueryParamsOnSearch = ['limit', 'offset'], // by default remove pagination
    ...rest
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeSearch = searchParams.get(searchName)?.trim() || '';
    const [showSearchForm, setShowSearchForm] = React.useState(false);
    const location = useLocation();

    const handleShowSearchForm = React.useCallback(() => {
        setShowSearchForm(true);
    }, []);

    const handleCloseSearchForm = React.useCallback(() => {
        setShowSearchForm(false);
    }, []);

    React.useEffect(() => {
        setShowSearchForm(location.search.includes(searchName));
    }, [location]);

    const schema = useMemo(() => getSearchSchema(searchName), [searchName]);
    const defaultValues = useMemo(
        () => ({ [searchName]: activeSearch }),
        [searchName, activeSearch]
    );
    const handleSubmit = useCallback(
        (params: Record<string, string>) => {
            setSearchParams((prevState) => {
                removeQueryParamsOnSearch.forEach((param) => {
                    prevState.delete(param);
                });
                params[searchName]?.length
                    ? prevState.set(searchName, params[searchName])
                    : prevState.delete(searchName);

                return prevState;
            });
        },
        [setSearchParams, searchName, removeQueryParamsOnSearch]
    );

    return showSearchForm ? (
        //@ts-ignore
        <Form validationSchema={schema} defaultValues={defaultValues} submitFn={handleSubmit}>
            <SearchBar
                name={searchName}
                activeSearch={activeSearch}
                onSubmit={handleSubmit}
                closeSearch={handleCloseSearchForm}
                {...rest}
            />
        </Form>
    ) : (
        <ClickableOpacity
            onClick={handleShowSearchForm}
            alt="Expand Search"
            styles={{ display: 'flex', alignItems: 'center', height: '5.2rem' }}
        >
            <SearchIcon size="small" style={{ marginRight: '1rem' }} />
            <Span>{activeSearch.length ? activeSearch : searchLabel}</Span>
        </ClickableOpacity>
    );
};

export default SearchForm;
