// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ePXrFG8qwFWLj7qfxSYx{display:flex;align-items:baseline}.ePXrFG8qwFWLj7qfxSYx div:not(:first-child){margin-left:10rem}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/Logs/HistoryLog/HistoryLog.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,oBAAA,CACA,4CACI,iBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.wrapper {\n    display: flex;\n    align-items: baseline;\n    div:not(:first-child) {\n        margin-left: 10rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ePXrFG8qwFWLj7qfxSYx`
};
export default ___CSS_LOADER_EXPORT___;
