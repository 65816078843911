import { useSetNotification } from '../../../context/Notification';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { GetOrganizationMembersParams } from './useGetOrganizationMembers';
import { format } from 'date-fns';
import { useState } from 'react';

const useGetExportMembers = (query?: GetOrganizationMembersParams, totalCount?: number) => {
    const request = useRequest('blob');
    const setNotification = useSetNotification();
    const [loading, setLoading] = useState(false);

    const preparedQuery = {
        offset: 0,
        limit: totalCount || query?.limit || null,
        ...(query?.state && { state: query.state }),
        ...(typeof query?.is_online !== 'undefined' && { is_online: query.is_online })
    };

    const exportMembers = async () => {
        setLoading(true);
        setNotification({ message: 'Exporting members' });

        const file = await request<Blob>({
            method: 'GET',
            url: `${envVars.API_COMMERCE_HOSTNAME}enterprise/export-members`,
            query: preparedQuery
        });

        const fileUrl = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = `Hero_Members_Export_${format(new Date(), 't')}`;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(fileUrl);
        setLoading(false);
        setNotification({ message: 'Members exported' });
    };

    return { exportMembers, loading };
};

export default useGetExportMembers;
