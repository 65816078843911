import React from 'react';

import Layout from '../../components/Layout';
import OrganizationMemberList from './OrganizationMemberList';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';
import PlusIcon from '@hero/ui-kit/icons/utility/PlusIcon';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import CreateMemberForm from './components/CreateMemberForm';
import UploadIcon from '@hero/ui-kit/icons/utility/UploadIcon';
import useListQueryParams from './OrganizationMemberList/useListQueryParams';
import useGetExportMembers from './api/useGetExportMembers';
import useGetOrganizationMembers from './api/useGetOrganizationMembers';
import LocationFilter from '../../components/LocationFilter';

const FleetManagement: React.FC = () => {
    const [createMemberModal, setCreateMemberModal] = React.useState(false);

    const { query } = useListQueryParams();
    const { isLoading, isError, data } = useGetOrganizationMembers(query);

    const { exportMembers, loading } = useGetExportMembers(query, data?.total_count);

    const headerElements = (
        <>
            <LocationFilter />
            <CircularButton
                disabled={loading}
                variant="secondary"
                isLink={false}
                onClick={exportMembers}
                marginLeft="small"
                className="flex-center"
                alt="Export Members"
                styles={{ opacity: loading ? 0.5 : 1 }}
            >
                <UploadIcon size="utility" styles={{ transform: 'rotateZ(180deg)' }} />
            </CircularButton>
            <CircularButton
                variant="secondary"
                isLink={false}
                onClick={(e) => {
                    e.stopPropagation();
                    setCreateMemberModal(true);
                }}
                marginLeft="small"
                className="flex-center"
                alt="Add Member"
            >
                <PlusIcon size="utility" />
            </CircularButton>
        </>
    );

    return (
        <>
            <Layout
                title="Members"
                search
                removeQueryParamsOnSearch={['limit', 'offset', 'online_status', 'device_status']}
                additionalElement={headerElements}
            >
                <OrganizationMemberList data={data} isError={isError} isLoading={isLoading} />
            </Layout>

            {createMemberModal && (
                <Modal
                    isCancelable
                    onClose={() => {
                        setCreateMemberModal(false);
                    }}
                    styles={{ padding: '0' }}
                >
                    <H role="h5">Add Member</H>
                    <CreateMemberForm onSuccess={() => setCreateMemberModal(false)} />
                </Modal>
            )}
        </>
    );
};

export default FleetManagement;
