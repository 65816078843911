// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PkfEpeji7EeNpHJAmfVx{min-height:3rem}.JpcgV_LAMOwvUnFwcmYG{border-bottom:2px solid var(--color-primary-alpha)}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/LeadsFilter/style.module.scss"],"names":[],"mappings":"AACA,sBACI,eAAA,CAGJ,sBACI,kDAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.btn {\n    min-height: 3rem;\n}\n\n.active {\n    border-bottom: 2px solid var(--color-primary-alpha);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `PkfEpeji7EeNpHJAmfVx`,
	"active": `JpcgV_LAMOwvUnFwcmYG`
};
export default ___CSS_LOADER_EXPORT___;
