// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bRfjhQnqkkTr7XUhSmqN{position:fixed;z-index:9999999;padding-top:100px;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:#000;background-color:rgba(0,0,0,.4)}.FRqgsG9yIu4wJ56RPwJg{background-color:#fefefe;margin:auto;padding:20px;border-radius:12px;width:42%}.zoIsw69D1RkbfTguplC2{display:flex;align-items:center;justify-content:space-between}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/Details/v2/components/UnsavedChangesModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,eAAA,CACA,iBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,qBAAA,CACA,+BAAA,CAGJ,sBACI,wBAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,SAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.modal {\n    position: fixed; /* Stay in place */\n    z-index: 9999999; /* Sit on top */\n    padding-top: 100px; /* Location of the box */\n    left: 0;\n    top: 0;\n    width: 100%; /* Full width */\n    height: 100%; /* Full height */\n    overflow: auto; /* Enable scroll if needed */\n    background-color: rgb(0,0,0); /* Fallback color */\n    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */\n  }\n\n.modalContent {\n    background-color: #fefefe;\n    margin: auto;\n    padding: 20px;\n    border-radius: 12px;\n    width: 42%;\n}\n\n  .modalHeader {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `bRfjhQnqkkTr7XUhSmqN`,
	"modalContent": `FRqgsG9yIu4wJ56RPwJg`,
	"modalHeader": `zoIsw69D1RkbfTguplC2`
};
export default ___CSS_LOADER_EXPORT___;
