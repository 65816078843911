// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.afi1Fgh5Tn3HMwZpHy4D{display:flex;align-items:center;column-gap:1.2rem;margin-top:1.2rem}.iLd4T2GnnRIz2MCH4y99 path{stroke-width:3 !important}.coHCmka3NIO8QJlRyK_c path{stroke-width:4 !important}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/Demographics/components/Caregivers/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CAIA,2BACI,yBAAA,CAKJ,2BACI,yBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.iconWrapper {\n    display: flex;\n    align-items: center;\n    column-gap: 1.2rem;\n    margin-top: 1.2rem;\n}\n\n.emailIcon {\n    path {\n        stroke-width: 3 !important;\n    }\n}\n\n.callIcon {\n    path {\n        stroke-width: 4 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconWrapper": `afi1Fgh5Tn3HMwZpHy4D`,
	"emailIcon": `iLd4T2GnnRIz2MCH4y99`,
	"callIcon": `coHCmka3NIO8QJlRyK_c`
};
export default ___CSS_LOADER_EXPORT___;
