import React from 'react';
import AlertIcon from '../../../../../../ui-kit/icons/utility/AlertIcon';

import Style from './Legend.module.scss';

const Legend: React.FC = () => {
    return (
        <div className={Style.root}>
            <div>
                <div
                    className={Style.circle}
                    style={{ backgroundColor: 'var(--color-success-beta)' }}
                ></div>
                <div>Taken</div>
            </div>
            <div>
                <div
                    className={Style.circle}
                    style={{ backgroundColor: 'var(--color-fail-alpha)' }}
                ></div>
                <div>Skipped/Missed</div>
            </div>
            <div>
                <div
                    className={Style.circle}
                    style={{ backgroundColor: 'var(--color-neutrals-borderBeta)' }}
                ></div>
                <div>Uknown</div>
            </div>
            <div>
                <div
                    className={Style.circle}
                    style={{ backgroundColor: 'var(--color-secondary-alpha)' }}
                ></div>
                <div>As-Needed</div>
            </div>
            <div>
                <AlertIcon type="inverted" size="utility" className={Style.triangle} />
                <div>Partial dispensed</div>
            </div>
        </div>
    );
};

export default Legend;
