import Section from '../../../../../ui-kit/layout/Section';
import React, { useCallback, useMemo, useState } from 'react';
import DosePills from '../DosePills';
import {
    parseTimeLabel,
    weekDaysLabels,
    getDate,
    isSameDay,
    dosesByPeriod,
    sortDoses
} from '../common';
import SideBar from '../../SideBar';

import Style from './Acw.module.scss';
import CommonStyle from '../common.module.scss';

import { AdhereceCalendarWeeklyProps, WeeklyDoses } from './Acw.types';
import SidebarPeriod from '../Sidebar/SidebarPeriod';
import { useSearchParams } from 'react-router-dom';
import { useActiveSidebars } from '../../SideBar/sidebarHooks';
import { PARAM_SIDEBAR } from '../../constants/searchParams';
import Loader from '../../../../../ui-kit/graphics/Loader';
import { useAdherenceContext } from '../../context/AdherenceContext';

const AdherenceCalendarWeekly: React.FC<AdhereceCalendarWeeklyProps> = ({ dateTo }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const sbd = useActiveSidebars();
    const [selected, setSelected] = useState(() => {
        return searchParams.get(PARAM_SIDEBAR) || '';
    });
    const today = new Date();

    const { getAdherenceCalendarWeekly } = useAdherenceContext();

    const [fetch, setFetch] = React.useState<{
        isLoading: boolean;
        data: WeeklyDoses[] | undefined;
    }>({
        isLoading: false,
        data: undefined
    });

    const { isLoading, data } = fetch;

    React.useEffect(() => {
        setFetch({ isLoading: true, data: undefined });
        getAdherenceCalendarWeekly({
            target_datetime: `${dateTo} 00:00:00`
        })
            .then((response) => setFetch({ isLoading: false, data: response }))
            .catch(() => setFetch({ isLoading: false, data: undefined }));
    }, [dateTo]);

    const weekDays = useMemo(
        () =>
            Array.from(Array(7)).map((_, index) => ({
                label: weekDaysLabels[index],
                value: getDate(new Date(`${dateTo}T00:00:00`), index - 6)
            })),
        [dateTo]
    );

    const parsedData = useMemo(() => dosesByPeriod(data || [], weekDays), [data, weekDays]);

    const sortedData = useMemo(() => sortDoses(parsedData), [parsedData]);

    const handlePillClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>, time: string) => {
            e.stopPropagation();
            e.preventDefault();
            setSelected(time);
            setSearchParams((prevState) => {
                prevState.set(PARAM_SIDEBAR, time);
                return prevState;
            });
        },
        [setSearchParams]
    );

    const renderData = useCallback(() => {
        if (isLoading || !sortedData)
            return (
                <div className={CommonStyle.loader}>
                    <Loader />
                </div>
            );
        return Array.from(sortedData).map(([key, value], index) => {
            return (
                <React.Fragment key={`period-${index}`}>
                    {index > 0 && <hr />}
                    <div
                        className={[
                            Style.periodRow,
                            key.includes('As-Needed') ? Style.asneeded : ''
                        ].join(' ')}
                    >
                        <div className={CommonStyle.timeLabel}>{parseTimeLabel(key)}</div>
                        <div className={CommonStyle.dosesDow}>
                            <>
                                <div className={Style.selectWrapper}>
                                    {weekDays.map(({ value }, i) => (
                                        <div
                                            key={`sw-${i}`}
                                            className={
                                                isSameDay(new Date(selected), value) &&
                                                selected.slice(-8) === key.slice(0, 8)
                                                    ? Style.selected
                                                    : ''
                                            }
                                        />
                                    ))}
                                </div>
                                {/* @ts-ignore */}
                                {Array.from(value).map(([key2, doseArr], index) => {
                                    return (
                                        <DosePills
                                            key={`dose-pills-${index}`}
                                            doses={doseArr}
                                            onClick={handlePillClick}
                                        />
                                    );
                                })}
                            </>
                        </div>
                    </div>
                </React.Fragment>
            );
        });
    }, [isLoading, sortedData, selected, handlePillClick, weekDays]);

    return (
        <Section noDefaultPadding border>
            <div className={CommonStyle.root}>
                <div className={CommonStyle.columnByDay} style={{ flex: 1 }} />
                <div style={{ flex: '10', display: 'flex', padding: '1.2rem' }}>
                    {weekDays.map((day, i) => (
                        <div className={CommonStyle.columnByDay} key={`header-${i}`}>
                            <div
                                className={[
                                    CommonStyle.heading,
                                    `${
                                        day.value && isSameDay(today, day.value)
                                            ? CommonStyle.active
                                            : ''
                                    }`
                                ].join(' ')}
                            >
                                <>
                                    <div>{day.label}</div>
                                    <strong>{day.value?.getDate()}</strong>
                                </>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <hr />
            <div className={Style.period} role="button" tabIndex={0} onKeyDown={() => {}}>
                {renderData()}
            </div>
            {sbd[0] && (
                <SideBar onOutsideClick={() => setSelected('')} listenToKeydown={sbd.length === 1}>
                    <SidebarPeriod day={sbd[0]} />
                </SideBar>
            )}
        </Section>
    );
};
export default AdherenceCalendarWeekly;
