// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Iqjh1gc_f0UlBACPQbwE{font-weight:bold;color:var(--color-primary-alpha)}`, "",{"version":3,"sources":["webpack://./components/HighlightedText/Highlight.module.scss"],"names":[],"mappings":"AACA,sBACI,gBAAA,CACA,gCAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.highlighted {\n    font-weight: bold;\n    color: var(--color-primary-alpha);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlighted": `Iqjh1gc_f0UlBACPQbwE`
};
export default ___CSS_LOADER_EXPORT___;
