import React from 'react';
import { useNavigate } from 'react-router-dom';
import Style from './style.module.scss';

interface PillLinkProps {
    children: React.ReactNode;
    to: string;
    variant?: 'primary' | 'secondary';
    className?: string;
    styles?: React.CSSProperties;
}

const PillLink: React.FC<PillLinkProps> = ({
    children,
    to,
    variant = 'primary',
    className = '',
    styles
}) => {
    const navigate = useNavigate();
    const handleNavigate = React.useCallback(
        (e: React.SyntheticEvent) => {
            e.preventDefault();
            navigate(to);
        },
        [to, navigate]
    );

    const variantClass = React.useMemo(() => {
        return variant === 'primary' ? Style.primary : Style.secondary;
    }, [variant]);

    return (
        <button
            className={`${Style.pill} ${variantClass} ${className}`}
            onClick={handleNavigate}
            style={styles}
        >
            {children}
        </button>
    );
};

export default PillLink;
