// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NeL3VtwuVcMHYe7lTm89:before{content:"|";margin-left:3rem;margin-right:3rem}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/MedicationInfo/style.module.scss"],"names":[],"mappings":"AAEI,6BACI,WAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.healthConditions {\n    &:before {\n        content: \"|\";\n        margin-left: 3rem;\n        margin-right: 3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"healthConditions": `NeL3VtwuVcMHYe7lTm89`
};
export default ___CSS_LOADER_EXPORT___;
