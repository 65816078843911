import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import * as React from 'react';
import Table from '../../../../components/Table';
import { MedicationDetails } from '../../types';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import MedDetails from '../MedDetails';
import MemberDetailsWrapper from '../MemberDetailsWrapper';
import Styles from './style.module.scss';

const emptyMedSlot: MedicationDetails = {
    name: null,
    slot: null,
    pill_schedules: null,
    expires: null,
    dosage: null,
    requires_pin: false,
    stored_in_hero: true,
    in_hero_from: null,
    level_warning: null,
    pill_level_enum: null,
    refill_prompt: false,
    form: null,
    is_generic: true,
    gpi: null,
    pill_type: null,
    max_pills_per_dispense: null,
    min_time_between_dispenses: null,
    max_doses: null,
    exact_pill_count: null
};

interface MedicationInfoProps {}

const MedicationInfo: React.FC<MedicationInfoProps> = () => {
    const { memberDetails, medications, isDeviceConfigured } =
        useOrganizationMemberDetailsContext();
    const { removed_medication, member_info } = memberDetails;
    const isMinL = useIsMinBreakpoint('l');

    const removedMeds = React.useMemo(() => {
        // no date param for now
        return (
            removed_medication?.map((med) => ({ name: med.name || 'No name', date: null })) || []
        );
    }, [removed_medication]);

    const preparedInsideMedications = React.useMemo(() => {
        const deviceSlots = 10;
        const slots = [];
        for (let i = 1; i <= deviceSlots; i++) {
            const findMed = medications.inside.find((med) => med.slot === i);
            slots.push(findMed ? findMed : { ...emptyMedSlot, slot: i });
        }
        return slots;
    }, [medications]);

    return (
        <>
            {member_info?.health_conditions?.length ? (
                <>
                    <P
                        noDefaultMargin
                        strong
                        size="large"
                        styles={{ marginBottom: '2rem', marginLeft: '2.4rem', marginTop: '2rem' }}
                    >
                        Health Conditions
                    </P>
                    <div style={{ marginLeft: '2.4rem', marginBottom: '4.8rem' }}>
                        {member_info?.health_conditions.map((codition, index) => (
                            <span key={`${codition.alias}-${codition.id}`}>
                                <span className={index !== 0 ? Styles.healthConditions : ''}>
                                    {codition.alias || ''}
                                </span>
                            </span>
                        ))}
                    </div>
                </>
            ) : null}
            {isDeviceConfigured ? (
                <>
                    <Section noDefaultPadding marginBottom="regular">
                        <Table
                            title="Inside Hero"
                            headerGridTemplateColumns="0.4fr 4.6fr 5fr 2fr"
                            headerColumns={['Slot', 'Name', 'Schedule', 'Allow As needed']}
                        >
                            {preparedInsideMedications.map((med, index) => (
                                <MedDetails
                                    key={`${index}-${med.name}`}
                                    med={med}
                                    isLastElement={preparedInsideMedications.length - 1 === index}
                                />
                            ))}
                        </Table>
                    </Section>

                    {medications.outside.length ? (
                        <Section noDefaultPadding marginBottom="regular">
                            <Table
                                title="Outside Hero"
                                headerGridTemplateColumns="0.4fr 4.6fr 5fr 2fr"
                                headerColumns={['Slot', 'Name', 'Schedule', 'Allow As needed']}
                            >
                                {medications.outside.map((med, index) => (
                                    <MedDetails
                                        key={`${index}-${med.name}`}
                                        med={med}
                                        isLastElement={medications.outside.length - 1 === index}
                                    />
                                ))}
                            </Table>
                        </Section>
                    ) : null}

                    {removedMeds.length ? (
                        <Table
                            collapsible
                            borderless
                            title="Removed Medication"
                            headerGridTemplateColumns="0.4fr 11.6fr"
                            headerColumns={['#', 'Medication Name']}
                        >
                            {removedMeds.map((med, index) => (
                                <Section
                                    noDefaultPadding
                                    paddingHorizontal="small"
                                    key={`${index}-${med.name}`}
                                >
                                    <Container
                                        gridTemplateColumns={isMinL ? '0.4fr 11.6fr' : '1fr'}
                                    >
                                        <P noDefaultMargin>{index + 1}</P>
                                        <P noDefaultMargin strong>
                                            {med.name || 'N/A'}
                                        </P>
                                    </Container>
                                </Section>
                            ))}
                        </Table>
                    ) : null}
                </>
            ) : null}
        </>
    );
};

const MedicationInfoWrapped = () => (
    <MemberDetailsWrapper>
        <Section noDefaultPadding paddingHorizontal="regular">
            <MedicationInfo />
        </Section>
    </MemberDetailsWrapper>
);

export default MedicationInfoWrapped;
