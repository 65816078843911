import React from 'react';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import CaretIcon from '@hero/ui-kit/icons/utility/CaretIcon';
import { OrganizationMemberDetails } from '../../types';
import useBack from '../../../../hooks/useBack';
import MenuNavbar from '../../../../components/Layout/MenuNavbar';
import UserInfo from '../MemberDetailsWrapper/UserInfo';
import Pill from '../../../LeadsManagement/components/Pill';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';

interface GeneralStickyNavbarProps {
    member?: OrganizationMemberDetails;
    showStickyNavbar?: boolean;
    isChanged?: boolean;
    isLoading?: boolean;
}

const StickyNavbar: React.FC<GeneralStickyNavbarProps> = ({
    member,
    isChanged = false,
    isLoading,
    showStickyNavbar
}) => {
    const { currentDevice } = useOrganizationMemberDetailsContext();
    const back = useBack();

    const handleBack = () => {
        back(localStorage.getItem('locationFrom') || '/members');
    };

    const isAdmin = currentDevice?.member_role === 'PRIMARY_USER_ADMIN';

    return (
        <div
            style={{
                position: 'sticky',
                top: 0,
                left: '0',
                background: 'rgba(255, 255, 255, 1)',
                minHeight: showStickyNavbar ? '60px' : '48px',
                zIndex: 10,
                opacity: showStickyNavbar ? 1 : 0,
                transition: 'all .2s ease-in-out',
                borderBottom: '1px solid rgba(231, 231, 231, 1)',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)'
            }}
        >
            <div
                style={{
                    minHeight: showStickyNavbar ? '60px' : '48px',
                    margin: '0 2.4rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        visibility: showStickyNavbar ? 'visible' : 'hidden'
                    }}
                >
                    <MenuNavbar hideLogo />
                    <ClickableOpacity
                        onClick={handleBack}
                        alt="back to leads"
                        styles={{ marginLeft: '1.6rem' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <CaretIcon
                                direction="backward"
                                width={20}
                                height={20}
                                styles={{ minWidth: '22px', marginRight: '1.6rem' }}
                            />

                            <P
                                noDefaultMargin
                                strong
                                styles={{ fontSize: '1.8rem', marginRight: '1.2rem' }}
                            >
                                {member?.member_info.first_name && member?.member_info?.last_name
                                    ? `${member.member_info.first_name} ${member.member_info.last_name}`
                                    : `-`}
                            </P>
                            <Pill
                                label={isAdmin ? 'Admin' : 'Read only'}
                                styles={{
                                    textTransform: 'none',
                                    fontWeight: '700',
                                    fontSize: '1.6rem',
                                    height: '3.2rem',
                                    borderRadius: '1.8rem',
                                    background: isAdmin ? 'rgba(255, 229, 214, 1)' : 'transparent',
                                    marginRight: '3.6rem',
                                    ...(!isAdmin && {
                                        border: '2px solid rgba(231, 231, 231, 1)'
                                    })
                                }}
                            />
                        </div>
                    </ClickableOpacity>
                    <UserInfo />
                </div>
                {isChanged ? (
                    <div>
                        <Button
                            noDefaultMargin
                            type="submit"
                            disabled={isLoading}
                            styles={{ lineHeight: '3.6rem' }}
                        >
                            Save
                        </Button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default StickyNavbar;
