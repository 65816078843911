import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import DotsMoreIcon from '@hero/ui-kit/icons/utility/DotsMoreIcon';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import CheckIcon from '@hero/ui-kit/icons/utility/CheckIcon';
import Style from './style.module.scss';
import { calcCurrentPage } from '@hero/ui-kit/tables/Pager/usePagination/utils';

interface LimitItemsProps {
    limit?: number;
    total_count?: number;
    offset?: number;
    className?: string;
    isLoading?: boolean;
}

const LimitItems: React.FC<LimitItemsProps> = ({
    limit,
    total_count,
    offset,
    className,
    isLoading = false
}) => {
    const [_, setSearchParams] = useSearchParams();
    const [showLimitBox, setShowLimitBox] = React.useState(false);

    const limits = [
        {
            label: '10 items',
            value: 10,
            isSelected: limit === 10
        },
        {
            label: '25 items',
            value: 25,
            isSelected: limit === 25
        },
        {
            label: '50 items',
            value: 50,
            isSelected: limit === 50
        }
    ];

    const handleLimitChange = (limit: number) => {
        setSearchParams((prev) => {
            prev.set('limit', `${limit}`);
            return prev;
        });
    };

    if (isLoading || !limit || !total_count) {
        return <div style={{ width: '12rem' }} />;
    }

    return limit && total_count ? (
        <div
            style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}
            className={className}
        >
            {`${calcCurrentPage(offset || 0, limit)}-${
                limit > total_count ? total_count : limit
            } of ${total_count}`}
            <div className={Style.filterStatus}>
                <ClickableOpacity
                    className={`${Style.filterStatusToggle} ${
                        showLimitBox ? Style.openLimitBox : ''
                    }`}
                    alt="expand items limiter"
                    onClick={() => setShowLimitBox((prev) => !prev)}
                >
                    <DotsMoreIcon width={36} height={36} size="large" />
                </ClickableOpacity>
                {showLimitBox && (
                    <Section
                        noDefaultPadding
                        className={`${Style.filterStatusDropdown}`}
                        border={true}
                    >
                        <>
                            <P strong noDefaultMargin className={Style.headerLabel}>
                                Show up to
                            </P>
                            {limits.map((limit) => {
                                return (
                                    <ClickableOpacity
                                        fullWidth
                                        key={limit.label}
                                        alt={`Toggle ${limit.label}`}
                                        className={`${Style.filterStatusItem} ${
                                            limit.isSelected ? Style.selected : ''
                                        }`}
                                        onClick={() => {
                                            handleLimitChange(limit.value);
                                        }}
                                    >
                                        <CheckIcon
                                            size="small"
                                            type="brand"
                                            styles={{
                                                visibility: limit.isSelected ? 'visible' : 'hidden'
                                            }}
                                        />
                                        <P noDefaultMargin>{limit.label}</P>
                                    </ClickableOpacity>
                                );
                            })}
                        </>
                    </Section>
                )}
            </div>
        </div>
    ) : null;
};

export default LimitItems;
