import P from '@hero/ui-kit/typography/P';
import React from 'react';
import Section from '@hero/ui-kit/layout/Section';
import Style from './style.module.scss';
import getApprovalStatus from '../utils/getApprovalStatus';
import { AllApprovalStatusSubStates, ApprovalStatus, LeadActivityDetail } from '../types';
import getApprovalSubStatus from '../utils/getApprovalSubStatus';
import { format, isValid } from 'date-fns';
import RefreshIcon from '../../../icons/RefreshIcon';

const LeadActivity: React.FC<{ activityDetails: LeadActivityDetail; isReactivated?: boolean }> = ({
    activityDetails,
    isReactivated = false
}) => {
    const approvalStatus = activityDetails?.status
        ? (activityDetails.status.toUpperCase() as ApprovalStatus)
        : undefined;
    const approvalSubStatus = activityDetails?.sub_status
        ? (activityDetails.sub_status.toUpperCase() as AllApprovalStatusSubStates)
        : undefined;
    const name = activityDetails.updated_by;
    // Thu, June 29, 2023 at 09:52 AM
    const updatedAt =
        activityDetails.updated_at && isValid(new Date(activityDetails.updated_at))
            ? new Date(activityDetails.updated_at)
            : undefined;
    const date = updatedAt
        ? format(
              new Date(
                  Date.UTC(
                      updatedAt.getFullYear(),
                      updatedAt.getMonth(),
                      updatedAt.getDate(),
                      updatedAt.getHours(),
                      updatedAt.getMinutes(),
                      updatedAt.getSeconds()
                  )
              ),
              'PPPPp'
          )
        : 'Invalid date';
    const status = activityDetails?.sub_status
        ? getApprovalSubStatus(approvalSubStatus, approvalStatus)
        : getApprovalStatus(approvalStatus);

    const scheduleDate =
        activityDetails?.note_datetime && isValid(new Date(activityDetails?.note_datetime))
            ? new Date(activityDetails?.note_datetime)
            : undefined;

    const preparedScheduledDate = scheduleDate
        ? new Date(
              Date.UTC(
                  scheduleDate.getFullYear(),
                  scheduleDate.getMonth(),
                  scheduleDate.getDate(),
                  scheduleDate.getHours(),
                  scheduleDate.getMinutes(),
                  scheduleDate.getSeconds()
              )
          )
        : undefined;

    const labelStyles: React.CSSProperties = {
        ...(status.label.toLowerCase() === 'enrolled' && { color: '#74a305' }),
        marginTop: '0.4rem'
    };

    return (
        <>
            <div className={Style.leadActivityItem}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '1rem',
                        marginBottom: '1.6rem'
                    }}
                >
                    {isReactivated ? (
                        <Section
                            styles={{
                                background: '#ffede1',
                                padding: '0.8rem 1.2rem',
                                borderRadius: '8px'
                            }}
                            noDefaultPadding
                        >
                            <div
                                style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}
                            >
                                <RefreshIcon type="utility" width={16} height={16} />
                                <P noDefaultMargin size="small" styles={{ fontWeight: 600 }}>
                                    Reactivated
                                </P>
                            </div>
                        </Section>
                    ) : null}
                    <P noDefaultMargin>
                        {name ? (
                            <span style={{ color: '#444444', fontWeight: 400 }}>{name}</span>
                        ) : (
                            <i>{`Automatically created via website form`}</i>
                        )}
                        <span style={{ fontSize: '1.4rem', color: '#777777' }}>{` • ${date}`}</span>
                    </P>
                </div>

                <P noDefaultMargin strong styles={labelStyles}>
                    {status.label}
                </P>
                {preparedScheduledDate ? (
                    <div className={Style.scheduleDateWrapper}>
                        <P noDefaultMargin className={Style.scheduleDate}>
                            {format(preparedScheduledDate, 'EEE, MMMM d')}
                        </P>
                        <P noDefaultMargin className={Style.scheduleDate}>
                            {format(preparedScheduledDate, 'p')}
                        </P>
                    </div>
                ) : null}
                {activityDetails.note ? (
                    <Section
                        styles={{
                            background: '#F4F4F4',
                            padding: '1.2rem 2.4rem',
                            marginTop: '2.4rem',
                            width: 'fit-content',
                            borderRadius: '8px'
                        }}
                        noDefaultPadding
                    >
                        <P noDefaultMargin>{activityDetails.note}</P>
                    </Section>
                ) : null}
            </div>
        </>
    );
};

export default LeadActivity;
