import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import React from 'react';
import Styles from './style.module.scss';
import EditIcon from '@hero/ui-kit/icons/utility/EditPenIcon';
import Modal from '@hero/ui-kit/components/Modal';
import { useRecorderContext } from '../../../../../../context/RecorderContext';
import Form from '@hero/ui-kit/inputs/Form';
import TextArea from '@hero/ui-kit/inputs/TextArea';
import activityScreenRecorderFormSchema, {
    ActivityScreenRecorderFormSchemaParams
} from './validator';
import XButton from '@hero/ui-kit/inputs/XButton';
import Countdown from '../Countdown';
import { SelectedPatientActivity } from '../../../../../../pages/FleetManagement/types';
import AlertIcon from '@hero/ui-kit/icons/utility/AlertIcon';
import Section from '@hero/ui-kit/layout/Section';
import useTheme from '@hero/branding/theme';
import InactivityModal from '../InactivityModal';
import Refill1Icon from '@hero/ui-kit/icons/utility/Refills1Icon';
import formatTime from '../../utils/formatTime';
import DeleteActivity from '../DeleteActivity';
import VideoPreview from '../VideoPreview';

interface ActivityScreenRecorderProps {}

const Timer: React.FC<{ time: number }> = ({ time }) => {
    return (
        <div>
            <span>{formatTime(time)}</span>
        </div>
    );
};

const CompleteModalState: React.FC<{
    patientActivity?: SelectedPatientActivity;
    onSubmit: (formElements: ActivityScreenRecorderFormSchemaParams) => void;
    onCloseModal: () => void;
    onStartOver: () => void;
    onDelete: () => void;
    time: number;
    mediaBlobUrl?: string;
    error?: boolean;
    isUploading?: boolean;
}> = ({
    patientActivity,
    onSubmit,
    time,
    onCloseModal,
    mediaBlobUrl,
    // onDelete,
    onStartOver,
    error = false,
    isUploading = false
}) => {
    const theme = useTheme();
    return (
        <>
            <div className={Styles.flexBetween}>
                <div style={{ fontSize: '2.4rem', fontWeight: 'bold' }}>Complete activity</div>
                <XButton onClick={onCloseModal} alt="Close patient activity modal" />
            </div>
            <div className={`${Styles.flexBetween} ${Styles.textSection}`}>
                <div className={Styles.flexAlign}>
                    {patientActivity?.icon}
                    <P noDefaultMargin>{patientActivity?.label || '-'}</P>
                </div>
                <div>
                    <P noDefaultMargin>
                        Duration: <strong>{`${formatTime(time, true)}`}</strong>
                    </P>
                </div>
            </div>
            {error ? (
                <Section
                    className={Styles.flexAlign}
                    styles={{ marginTop: '3.6rem', marginBottom: '2.4rem' }}
                    centered
                >
                    <AlertIcon width={20} height={20} />
                    <P noDefaultMargin strong styles={{ color: theme.colors.status.fail.alpha }}>
                        Completion failed due to network issues. Please try again.
                    </P>
                </Section>
            ) : null}
            {mediaBlobUrl ? (
                <VideoPreview
                    className={Styles.videoStyle}
                    videoUrl={mediaBlobUrl}
                    isUploading={isUploading}
                />
            ) : null}
            <Form
                validationSchema={activityScreenRecorderFormSchema}
                submitFn={onSubmit}
                render={(_, formState) => {
                    return (
                        <>
                            <TextArea
                                disabled={isUploading}
                                displayName="Note"
                                name="note"
                                className={Styles.textarea}
                            />
                            <div className={Styles.flexEnd}>
                                <div className={Styles.flexAlign}>
                                    <Button
                                        onClick={onStartOver}
                                        variant="outlineSecondary"
                                        disabled={isUploading}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '1rem'
                                            }}
                                        >
                                            <Refill1Icon
                                                disabled={isUploading}
                                                size="utility"
                                                width={20}
                                                height={20}
                                            />
                                            <strong>Start Over</strong>
                                        </div>
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={!formState.isValid || isUploading}
                                    >
                                        Done
                                    </Button>
                                </div>
                            </div>
                        </>
                    );
                }}
            />
        </>
    );
};

const EditModalState: React.FC<{
    patientActivity?: SelectedPatientActivity;
    onSubmit: (formElements: ActivityScreenRecorderFormSchemaParams) => void;
    onCloseModal: () => void;
    time: number;
}> = ({ patientActivity, onSubmit, time, onCloseModal }) => {
    return (
        <>
            <div className={Styles.flexBetween}>
                <div style={{ fontSize: '2.4rem', fontWeight: 'bold' }}>Note</div>
                <XButton onClick={onCloseModal} alt="Close patient activity modal" />
            </div>
            <div className={`${Styles.textSection}`}>
                <div className={Styles.flexAlign}>
                    {patientActivity?.icon}
                    <P noDefaultMargin>{patientActivity?.label || '-'}</P>
                </div>
            </div>
            <Form
                validationSchema={activityScreenRecorderFormSchema}
                submitFn={onSubmit}
                render={(_, formState) => {
                    return (
                        <>
                            <TextArea displayName="Note" name="note" className={Styles.textarea} />
                            <div className={Styles.flexBetween}>
                                <div className={Styles.flexAlign}>
                                    <div className={`${Styles.recordingBtn}`}>
                                        <div />
                                    </div>
                                    <Timer time={time} />
                                </div>
                                <div className={Styles.flexAlign}>
                                    <Button variant="outlineSecondary" onClick={onCloseModal}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" disabled={!formState.isValid}>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </>
                    );
                }}
            />
        </>
    );
};

let interval: NodeJS.Timeout | null = null;

const ActivityScreenRecorder: React.FC<ActivityScreenRecorderProps> = () => {
    const {
        stopRecording,
        mediaBlobUrl,
        status,
        clearRecording,
        patientActivity,
        startRecording,
        getMediaStream
    } = useRecorderContext();

    const [showModal, setShowModal] = React.useState(false);
    const [modalState, setModalState] = React.useState<'edit' | 'complete'>('edit');
    const [showTimer, setShowTimer] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const isUploading = false;

    const [time, setTime] = React.useState(0);

    React.useEffect(() => {
        if (status === 'recording') {
            interval = setInterval(() => {
                setTime((time) => time + 1000);
            }, 1000);
        } else {
            interval && clearInterval(interval);
        }
        return () => {
            interval && clearInterval(interval);
        };
    }, [status]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const onDelete = () => {
        handleCloseModal();
        setShowDeleteModal(false);
        clearRecording();
        setTime(0);
    };

    const handleStop = () => {
        setModalState('complete');
        stopRecording();
        setShowModal(true);
    };

    const handleEditNote = () => {
        setModalState('edit');
        setShowModal(true);
    };

    const startOver = () => {
        clearRecording();
        handleCloseModal();
        setModalState('edit');
        setTime(0);
        getMediaStream().then((mediaStream) => {
            if (mediaStream?.current?.active) {
                setShowTimer(true);
            }
        });
    };

    const handleOnTimerEnd = () => {
        setShowTimer(false);
        startRecording();
    };

    const handleSubmit = (formElements: ActivityScreenRecorderFormSchemaParams) => {
        console.log(formElements.note, 'note');
    };

    React.useEffect(() => {
        if (status === 'stopped') {
            setShowModal(true);
            setModalState('complete');
        }
    }, [status]);

    if ((status === 'idle' || status === 'acquiring_media') && !showTimer) {
        return null;
    }

    return (
        <>
            <div className={Styles.mainWrapper}>
                <div className={Styles.main}>
                    <div className={Styles.flexBetween}>
                        <div>
                            <div className={Styles.flexAlign}>
                                <div className={`${Styles.recordingBtn}`}>
                                    <div />
                                </div>
                                <Timer time={time} />
                            </div>
                        </div>
                        <div className={Styles.playerBtnWrapper}>
                            <Button
                                noDefaultMargin
                                variant="outlineSecondary"
                                onClick={handleEditNote}
                                className={`${Styles.playerBtn} ${Styles.editBtn}`}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.8rem'
                                    }}
                                >
                                    <EditIcon size="utility" width={20} height={20} />
                                    Note
                                </div>
                            </Button>
                            <Button
                                noDefaultMargin
                                onClick={handleStop}
                                className={Styles.playerBtn}
                            >
                                Stop
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                externalControls={[showModal, setShowModal]}
                styles={{ padding: '0', width: '50rem' }}
            >
                <div style={{ width: '100%' }}>
                    {modalState === 'complete' && (
                        <CompleteModalState
                            onCloseModal={() => setShowDeleteModal(true)}
                            onStartOver={startOver}
                            onDelete={() => setShowDeleteModal(true)}
                            patientActivity={patientActivity}
                            onSubmit={handleSubmit}
                            time={time}
                            mediaBlobUrl={mediaBlobUrl}
                            isUploading={isUploading}
                        />
                    )}
                    {modalState === 'edit' && (
                        <EditModalState
                            onCloseModal={handleCloseModal}
                            patientActivity={patientActivity}
                            onSubmit={handleSubmit}
                            time={time}
                        />
                    )}
                </div>
            </Modal>
            <DeleteActivity
                externalControls={[showDeleteModal, setShowDeleteModal]}
                onDelete={onDelete}
                onCancel={() => setShowDeleteModal(false)}
                time={time}
            />
            {showTimer ? <Countdown onEnd={handleOnTimerEnd} /> : null}
            {status === 'recording' ? <InactivityModal /> : null}
        </>
    );
};

export default ActivityScreenRecorder;
