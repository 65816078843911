import P from '@hero/ui-kit/typography/P';
import {
    endOfDay,
    endOfYesterday,
    format,
    isValid,
    startOfDay,
    startOfYesterday,
    subDays,
    subMonths
} from 'date-fns';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import Filter from '../../components/Filter';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import Style from './style.module.scss';
import Modal from '@hero/ui-kit/components/Modal';
import Section from '@hero/ui-kit/layout/Section';
import DatePicker from '@hero/ui-kit/components/DatePicker';
import Button from '@hero/ui-kit/inputs/Button';
import XButton from '@hero/ui-kit/inputs/XButton';
import CheckIcon from '@hero/ui-kit/icons/utility/CheckIcon';

interface DateFilterProps {
    searchParam: string;
    defaultLabel: string;
    disableFilter?: string[];
}

const labelDateFormat = 'dd/MM/yyyy';
const formatDate = 'yyyy-MM-dd H:mm:ss';

const formatRangeDate = (dates: string) => {
    const splitDates = dates.split('|');

    return `${format(new Date(splitDates[0]), labelDateFormat)} - ${format(
        new Date(splitDates[1]),
        labelDateFormat
    )}`;
};

const DateFilter: React.FC<DateFilterProps> = ({
    searchParam,
    defaultLabel,
    disableFilter = []
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [showRangeModal, setShowRangeModal] = React.useState(false);
    const [startDate, setStartDate] = React.useState<Date | null>();
    const [endDate, setEndDate] = React.useState<Date | null>();

    const setQueryDateParam = React.useCallback(
        (date: string) => {
            setSearchParams((prev) => {
                prev.set(searchParam, date);

                prev.delete('offset');

                return prev;
            });
        },
        [searchParam, setSearchParams]
    );

    const updateStatusFilter = React.useCallback(
        (date: string) => {
            if (date === 'date-range') {
                setShowRangeModal(true);
            } else if (date === 'any-time') {
                setStartDate(null);
                setEndDate(null);
                setSearchParams((searchParams) => {
                    searchParams.delete(searchParam);

                    return searchParams;
                });
            } else {
                setStartDate(null);
                setEndDate(null);
                setQueryDateParam(date);
            }
        },
        [setSearchParams, searchParam, setQueryDateParam]
    );

    const today = new Date();
    const startOfToday = format(startOfDay(today), formatDate);
    const endOfToday = format(endOfDay(today), formatDate);

    const selectedFilter = searchParams.get(searchParam);
    const todayValue = `${startOfToday}|${endOfToday}`;
    const yesterdayValue = `${format(startOfYesterday(), formatDate)}|${format(
        endOfYesterday(),
        formatDate
    )}`;
    const last7daysValue = `${format(startOfDay(subDays(today, 7)), formatDate)}|${endOfToday}`;
    const last30daysValue = `${format(startOfDay(subDays(today, 30)), formatDate)}|${endOfToday}`;
    const last3monthsValue = `${format(startOfDay(subMonths(today, 3)), formatDate)}|${endOfToday}`;
    const last12monthsValue = `${format(
        startOfDay(subMonths(today, 12)),
        formatDate
    )}|${endOfToday}`;

    const isRangeSelected =
        !!selectedFilter &&
        ![
            todayValue,
            yesterdayValue,
            last7daysValue,
            last30daysValue,
            last3monthsValue,
            last12monthsValue
        ].includes(selectedFilter || '');

    const dates = [
        {
            label: 'Any time',
            value: `any-time`,
            isSelected: false,
            isDisabled: false
        },
        {
            label: 'Today',
            value: todayValue,
            isSelected: todayValue === selectedFilter,
            isDisabled: disableFilter.includes('today')
        },
        {
            label: 'Yesterday',
            value: yesterdayValue,
            isSelected: yesterdayValue === selectedFilter,
            isDisabled: disableFilter.includes('yesterday')
        },
        {
            label: 'Last 7 days',
            value: last7daysValue,
            isSelected: last7daysValue === selectedFilter,
            isDisabled: disableFilter.includes('last_7_days')
        },
        {
            label: 'Last 30 days',
            value: last30daysValue,
            isSelected: last30daysValue === selectedFilter,
            isDisabled: disableFilter.includes('last_30_days')
        },
        {
            label: 'Last 3 months',
            value: last3monthsValue,
            isSelected: last3monthsValue === selectedFilter,
            isDisabled: disableFilter.includes('last_3_months')
        },
        {
            label: 'Last 12 months',
            value: last12monthsValue,
            isSelected: last12monthsValue === selectedFilter,
            isDisabled: disableFilter.includes('last_12_months')
        },
        ...(isRangeSelected
            ? [
                  {
                      label: formatRangeDate(selectedFilter),
                      value: 'date-range',
                      isSelected: true,
                      isDisabled: false
                  }
              ]
            : []),
        {
            label: 'Range...',
            value: 'date-range',
            isSelected: false,
            isDisabled: disableFilter.includes('range')
        }
    ];

    const filterLabel = React.useMemo(() => {
        const date = searchParams.get(searchParam);

        if (date) {
            const dates = date.split('|');

            if (todayValue === selectedFilter) {
                return `${defaultLabel}: ${format(new Date(), labelDateFormat)}`;
            }

            if (yesterdayValue === selectedFilter) {
                return `${defaultLabel}: ${format(startOfYesterday(), labelDateFormat)}`;
            }

            return `${defaultLabel}: ${format(new Date(dates[0]), labelDateFormat)} - ${format(
                new Date(dates[1]),
                labelDateFormat
            )}`;
        }

        return defaultLabel;
    }, [searchParam, searchParams, defaultLabel, todayValue, selectedFilter, yesterdayValue]);

    const onChange = (dates: [start: Date, end: Date]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === 'Enter' && event.target instanceof HTMLInputElement) {
            const value = event.target.value;
            const splitDates = value.split('-');

            const startDate =
                splitDates[0] && isValid(new Date(splitDates[0])) ? new Date(splitDates[0]) : null;

            const endDate =
                splitDates[1] && isValid(new Date(splitDates[1])) ? new Date(splitDates[1]) : null;

            setStartDate(startDate);
            setEndDate(endDate);
        }
    };

    const saveDateRange = () => {
        if (startDate && endDate) {
            setQueryDateParam(
                `${format(startOfDay(startDate), formatDate)}|${format(
                    endOfDay(endDate),
                    formatDate
                )}`
            );
        }
        setShowRangeModal(false);
    };

    const date = searchParams.get(searchParam);

    return (
        <>
            <Filter
                hasFilters={typeof date === 'string'}
                filterLabel={filterLabel}
                ignoreClickOutside={showRangeModal}
                width={isRangeSelected ? '28rem' : '24rem'}
            >
                {dates
                    .filter((date) => !date.isDisabled)
                    .map((date) => {
                        return (
                            <ClickableOpacity
                                fullWidth
                                key={date.label}
                                alt={`Toggle ${date.label}`}
                                className={`${Style.filterStatusItem} ${
                                    date.isSelected ? Style.selected : ''
                                }`}
                                onClick={() => {
                                    updateStatusFilter(date.value);
                                }}
                            >
                                {date.isSelected ? <CheckIcon size="small" type="brand" /> : null}
                                <P noDefaultMargin>{date.label}</P>
                            </ClickableOpacity>
                        );
                    })}
            </Filter>
            <Modal externalControls={[showRangeModal, setShowRangeModal]} noDefaultPadding>
                <Section>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginBottom: '2.4rem'
                        }}
                    >
                        <P size="large" strong noDefaultMargin>
                            Range...
                        </P>
                        <XButton alt="close" onClick={() => setShowRangeModal(false)} />
                    </div>
                    <DatePicker
                        //@ts-ignore
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                        startDate={startDate}
                        endDate={endDate}
                        //@ts-ignore
                        selectsRange={true}
                        inline
                    />
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: '1rem'
                        }}
                    >
                        <Button
                            size="small"
                            variant="secondary"
                            onClick={() => setShowRangeModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            onClick={saveDateRange}
                            disabled={!startDate || !endDate}
                        >
                            Save
                        </Button>
                    </div>
                </Section>
            </Modal>
        </>
    );
};

export default DateFilter;
