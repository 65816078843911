import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';
import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Style from './style.module.scss';
import React from 'react';
import P from '@hero/ui-kit/typography/P';
import Button from '@hero/ui-kit/inputs/Button';
import Form, { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import { PatientDetailsFormSchemaParams, patientDetailsFormSchema } from './validator';
import { LeadDetailsResponse, LeadInsuranceResponse } from '../../types';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';
import useUpdateLead from '../../api/useUpdateLead';
import StandaloneCheckbox from '@hero/ui-kit/inputs/StandaloneCheckbox';
import LeadErrorModal from '../LeadErrorModal';
import ExistingHeroUserFormFields from '../ExistingHeroUserFormFields';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import useGetLeadInsurance from '../../api/useGetLeadInsurance';
import { getDOBFormat } from '../../../../utils/date';

interface PatientDetailsFormProps {
    lead?: LeadDetailsResponse;
    onSuccess?: () => void;
}

const FormElements: React.FC<{
    isSaveDisabled?: boolean;
    showAdminEmailError?: boolean;
    showHeroSerialErrorError?: boolean;
    leadInsurance?: LeadInsuranceResponse;
}> = ({
    isSaveDisabled = false,
    showAdminEmailError = false,
    showHeroSerialErrorError = false
}) => {
    const isMinL = useIsMinBreakpoint('l');
    const isMember = useWatch({ name: 'is_member' });
    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    const insuranceType = useWatch({ name: 'insurance_type' });

    return (
        <>
            <Section noDefaultPadding>
                <div style={{ width: '100%', textAlign: 'left', marginBottom: 'var(--gutter)' }}>
                    <P noDefaultMargin strong>
                        Patient details
                    </P>
                </div>
                <div style={{ marginBottom: 'var(--gutter)' }}>
                    <StandaloneCheckbox name="is_member" labelText="Already using Hero dispenser" />
                </div>
                {isMember ? (
                    <ExistingHeroUserFormFields
                        showAdminEmailError={showAdminEmailError}
                        showHeroSerialErrorError={showHeroSerialErrorError}
                    />
                ) : null}
                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                    <Input name="date_of_birth" displayName="Date of birth" disabled />
                    <Input name="zip_code" displayName="zip" disabled />
                </Container>

                <Container gridTemplateColumns={isMinL ? '1fr 1fr' : '1fr'} gridColumnGap="regular">
                    <Input name="patient_email" displayName="Patient Email" />
                    <Input name="patient_phone" displayName="Patient Phone number" />
                </Container>
                <div style={{ width: '100%', textAlign: 'left' }}>
                    <P noDefaultMargin strong>
                        Insurance Type
                    </P>
                </div>
                <RadioGroup
                    className={Style.radioGroup}
                    name="insurance_type"
                    displayName=""
                    noBorders
                >
                    <Radio
                        id="original_medicare"
                        value="ORIGINAL_MEDICARE"
                        labelText="Original Medicare"
                        className={Style.radio}
                    />

                    <Radio
                        id="medicare_advantage"
                        value="MEDICARE_ADVANTAGE"
                        labelText="Medicare Advantage"
                        className={Style.radio}
                    />
                    <Radio
                        id="other"
                        value="OTHER_INSURANCE"
                        labelText="Other insurance"
                        className={Style.radio}
                    />
                    <Radio
                        id="not_sure"
                        value="NOT_SURE"
                        labelText="Not sure"
                        className={Style.radio}
                    />
                </RadioGroup>
                <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                    <Input name="insurance_id" displayName="Member ID" />
                    {['MEDICARE_ADVANTAGE', 'NOT_SURE', 'OTHER_INSURANCE'].includes(
                        insuranceType || ''
                    ) ? (
                        <Input name="insurance_company" displayName="Insurance Company" />
                    ) : null}
                </Container>
                <Section centered noDefaultPadding>
                    <Button type="submit" disabled={isSaveDisabled} className={Style.saveBtn}>
                        Save
                    </Button>
                </Section>
            </Section>
        </>
    );
};

const PatientDetailsForm: React.FC<PatientDetailsFormProps> = ({ lead, onSuccess }) => {
    const { mutate, isPending: isLoading, isError, error } = useUpdateLead(onSuccess);
    const [formAttributes, setFormAttributes] = React.useState<
        PatientDetailsFormSchemaParams | undefined
    >();

    const [showErrorModal, setShowErrorModal] = React.useState(false);

    const { data: leadInsurance } = useGetLeadInsurance(lead?.primary_user_details.lead_id || '');

    const handleSubmit = (attributes: PatientDetailsFormSchemaParams) => {
        if (!attributes.isDirty) {
            onSuccess && onSuccess();
            return;
        }
        setFormAttributes(attributes);
        const {
            admin_email,
            external_serial,
            is_member,
            insurance_id,
            insurance_company,
            insurance_type
        } = attributes;
        const leadId = lead?.primary_user_details.lead_id;

        leadId &&
            mutate({
                query: { lead_id: leadId },
                payload: {
                    is_member,
                    phone: formatPhoneNumber(attributes.patient_phone),
                    email: attributes.patient_email,
                    insurance_type,
                    ...(admin_email && { admin_email }),
                    ...(insurance_company && {
                        insurance_carrier_by_user:
                            insurance_type === 'ORIGINAL_MEDICARE' ? null : insurance_company
                    }),
                    ...(insurance_id && { insurance_policy_id: insurance_id }),
                    ...(external_serial && { external_serial })
                }
            });
    };

    const handleIgnoreOrgAllowSubmit = () => {
        const leadId = lead?.primary_user_details.lead_id;
        if (formAttributes && leadId) {
            const {
                admin_email,
                external_serial,
                is_member,
                insurance_type,
                insurance_company,
                insurance_id
            } = formAttributes;
            setShowErrorModal(false);
            mutate({
                query: { lead_id: leadId },
                payload: {
                    ignore_org_allowed_transfer: true,
                    is_member,
                    insurance_type,
                    phone: formatPhoneNumber(formAttributes.patient_phone),
                    email: formAttributes.patient_email,
                    ...(admin_email && { admin_email }),
                    ...(external_serial && { external_serial }),
                    ...(insurance_company && { insurance_carrier_by_user: insurance_company }),
                    ...(insurance_id && { insurance_policy_id: insurance_id })
                }
            });
        }
    };
    const errorType = error?.errors[0];

    React.useEffect(() => {
        if (isError && !['serial_not_exist', 'email_not_exist'].includes(errorType || '')) {
            setShowErrorModal(true);
        }
    }, [isError, errorType]);

    return (
        <Form
            submitFn={handleSubmit}
            validationSchema={patientDetailsFormSchema}
            defaultValues={{
                insurance_type: lead?.primary_user_details?.insurance_type?.toUpperCase(),
                zip_code: lead?.primary_user_details?.zip,
                date_of_birth: getDOBFormat(lead?.primary_user_details?.date_of_birth),
                patient_email: lead?.primary_user_details?.email,
                patient_phone: lead?.primary_user_details?.phone,
                is_member: lead?.is_existing_member,
                admin_email: lead?.primary_user_details.admin_email,
                external_serial: lead?.primary_user_details.external_serial,
                insurance_id: lead?.primary_user_details?.insurance_id,
                insurance_company: lead?.primary_user_details?.insurance_company
            }}
        >
            <FormElements
                leadInsurance={leadInsurance}
                isSaveDisabled={isLoading}
                showAdminEmailError={errorType === 'email_not_exist'}
                showHeroSerialErrorError={errorType === 'serial_not_exist'}
            />

            <LeadErrorModal
                externalControls={[showErrorModal, setShowErrorModal]}
                onCancel={() => setShowErrorModal(false)}
                onSave={
                    errorType === 'transfer_not_allowed' ? handleIgnoreOrgAllowSubmit : undefined
                }
                isLoading={isLoading}
                errorType={errorType}
            />
        </Form>
    );
};

export default PatientDetailsForm;
