import React from 'react';

import CaretIcon from '@hero/ui-kit/icons/utility/CaretIcon';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Pager from '@hero/ui-kit/tables/Pager';

import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import usePagerProps from '../../hooks/usePagerProps';
import Loading from '../States/Loading';
import Style from './style.module.scss';
import { Size } from '@hero/branding/types';

type Props = {
    children: React.ReactNode;
    headerColumns?: React.ReactNode[];
    headerGridTemplateColumns?: React.CSSProperties['gridTemplateColumns'];
    pagerData?: { total_count?: number; limit?: number; offset?: number };
    loading?: boolean;
    title?: string;
    collapsible?: boolean;
    borderless?: boolean;
    gridColumnGap?: Size;
    style?: React.CSSProperties;
};

const Table: React.FC<Props> = ({
    children,
    headerColumns,
    headerGridTemplateColumns,
    pagerData,
    loading,
    title,
    collapsible,
    borderless,
    gridColumnGap,
    style
}) => {
    const [collapsed, setCollapsed] = React.useState(!!collapsible);

    const pagerProps = usePagerProps({
        total_count: pagerData?.total_count || 0,
        limit: pagerData?.limit || 0,
        offset: pagerData?.offset || 0
    });

    const table = (
        <>
            {headerColumns?.length && (
                <Section padding="small" paddingTop={title ? 'none' : undefined}>
                    <Container
                        gridTemplateColumns={headerGridTemplateColumns}
                        gridColumnGap={gridColumnGap}
                    >
                        {headerColumns.map((item, index) => (
                            <div key={`${index}-${item}`}>{item}</div>
                        ))}
                    </Container>
                </Section>
            )}

            {loading ? (
                <Loading />
            ) : (
                <>
                    <ul
                        className={`${Style.table} ${borderless ? Style.borderless : ''}`}
                        style={style}
                    >
                        {children instanceof Array ? (
                            children.map((child, index) => <li key={`${index}-child`}>{child}</li>)
                        ) : (
                            <li>{children}</li>
                        )}
                    </ul>

                    <Pager {...pagerProps} />
                </>
            )}
        </>
    );

    if (title) {
        return (
            <Section noDefaultPadding className={Style.tableWrapper}>
                <Section padding="small">
                    {collapsible ? (
                        <ClickableOpacity
                            alt={`${collapsed ? 'Expand' : 'Collapse'} table`}
                            onClick={() => {
                                setCollapsed((prevState) => !prevState);
                            }}
                            className="icon-title"
                        >
                            <strong>{title}</strong>
                            <CaretIcon direction={collapsed ? 'down' : 'up'} size="utility" />
                        </ClickableOpacity>
                    ) : (
                        <strong>{title}</strong>
                    )}
                </Section>

                {!collapsed && table}
            </Section>
        );
    }

    return table;
};

export default Table;
