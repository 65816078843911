// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E2fAyCPC7MI71kcKxzrH{margin-left:1rem;width:3.2rem;height:3.2rem;border-radius:100% !important;border-width:.2rem !important;border-style:solid !important;border-color:var(--color-neutrals-borderBeta) !important}.E2fAyCPC7MI71kcKxzrH._5i3WdJdZnhER5swV105w{border-color:var(--color-primary-alpha) !important}.E2fAyCPC7MI71kcKxzrH:hover{background-color:var(--color-secondary-gamma) !important}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberList/Caregivers/style.module.scss"],"names":[],"mappings":"AACA,sBACI,gBAAA,CACA,YAAA,CACA,aAAA,CACA,6BAAA,CACA,6BAAA,CACA,6BAAA,CACA,wDAAA,CAEA,4CACI,kDAAA,CAEJ,4BACI,wDAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.moreBtn {\n    margin-left: 1rem;\n    width: 3.2rem;\n    height: 3.2rem;\n    border-radius: 100% !important;\n    border-width: 0.2rem !important;\n    border-style: solid !important;\n    border-color: var(--color-neutrals-borderBeta) !important;\n\n    &.highlighted {\n        border-color: var(--color-primary-alpha) !important;\n    }\n    &:hover {\n        background-color: var(--color-secondary-gamma) !important;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"moreBtn": `E2fAyCPC7MI71kcKxzrH`,
	"highlighted": `_5i3WdJdZnhER5swV105w`
};
export default ___CSS_LOADER_EXPORT___;
