import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import envVars from '../../../constants/envVars';
import useRequest from '../../../hooks/useRequest';
import { OrganizationMemberListPaginated } from '../types';
import { ORGANIZATION_MEMBER_LIST_KEY } from './constants';

export type GetOrganizationMembersParams = {
    search?: string;
    offset?: string;
    limit?: number;
    is_online?: boolean;
    device_status?: string;
    state?: string;
};

const useGetOrganizationMembers = (query: GetOrganizationMembersParams) => {
    const req = useRequest();

    const action = useCallback(() => {
        return req<OrganizationMemberListPaginated>({
            url: `${envVars.API_COMMERCE_HOSTNAME}enterprise/members`,
            query
        });
    }, [req, query]);

    return useQuery({
        queryFn: action,
        queryKey: [ORGANIZATION_MEMBER_LIST_KEY, query]
    });
};

export default useGetOrganizationMembers;
