import React from 'react';
import Style from './style.module.scss';

const Pill: React.FC<{ label: string; tooltipLabel?: string; className?: string; styles?: {} }> = ({
    label,
    tooltipLabel,
    className = '',
    styles = {}
}) => {
    return (
        <div className={`${Style.pill} ${className}`} title={tooltipLabel} style={styles}>
            {label}
        </div>
    );
};

export default Pill;
