// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SbuODWtOXGEQLXmZVpn0{display:flex;align-items:center;justify-content:space-between;margin:1.6rem 0;padding:0 1.6rem !important;outline:none !important}.Q2n0pG9aJ2TXXkMAXuOt{max-height:62vh;overflow-y:auto}`, "",{"version":3,"sources":["webpack://./pages/UsersManagement/UserList/InviteStatusFilter/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CACA,2BAAA,CACA,uBAAA,CAGJ,sBACI,eAAA,CACA,eAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.filterStatusItem {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin: 1.6rem 0;\n    padding: 0 1.6rem !important;\n    outline: none !important;\n}\n\n.filterWrapper {\n    max-height: 62vh;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterStatusItem": `SbuODWtOXGEQLXmZVpn0`,
	"filterWrapper": `Q2n0pG9aJ2TXXkMAXuOt`
};
export default ___CSS_LOADER_EXPORT___;
