// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dU7GDRfjFur2GkLsKBMg{display:flex;align-items:center;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/SuccessEnrollModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.header {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `dU7GDRfjFur2GkLsKBMg`
};
export default ___CSS_LOADER_EXPORT___;
