// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dwmZE1yx6oPRC3ToDyl1{display:flex;align-items:center;column-gap:1rem;margin:1.6rem 0;padding:0 1.6rem !important;outline:none !important}.sZYlWWdBAbeR7X6HAxld p{color:var(--color-primary-alpha) !important;font-weight:bold !important}`, "",{"version":3,"sources":["webpack://./components/DateFilter/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,eAAA,CACA,eAAA,CACA,2BAAA,CACA,uBAAA,CAIA,wBACI,2CAAA,CACA,2BAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.filterStatusItem {\n    display: flex;\n    align-items: center;\n    column-gap: 1rem;\n    margin: 1.6rem 0;\n    padding: 0 1.6rem !important;\n    outline: none !important;\n}\n\n.selected {\n    p {\n        color: var(--color-primary-alpha) !important;\n        font-weight: bold !important;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterStatusItem": `dwmZE1yx6oPRC3ToDyl1`,
	"selected": `sZYlWWdBAbeR7X6HAxld`
};
export default ___CSS_LOADER_EXPORT___;
