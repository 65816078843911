// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Vkzmz9N_o6fe03lRvmf{display:flex;align-items:center;justify-content:space-between;margin-bottom:3.6rem}.nCxlzvOHmpp0lgJ8ey6N{display:flex;align-items:center;justify-content:flex-end;margin-top:3.6rem}.gkWI4THhCXfb5i6Jll5M{display:flex;align-items:center;column-gap:1.6rem;background:#f4f4f4;padding:1.6rem;border-radius:.8rem;margin-bottom:var(--gutter)}.wOsTbwziCQal1MXpsGJM{font-size:14px;font-weight:400}.JuvSrY_uzzubZjdoP30L{font-size:14px;font-weight:600;border:none;background:rgba(0,0,0,0);color:var(--color-primary-alpha) !important;cursor:pointer}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/DeviceInfo/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,oBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,kBAAA,CACA,cAAA,CACA,mBAAA,CACA,2BAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CAGJ,sBACI,cAAA,CACA,eAAA,CACA,WAAA,CACA,wBAAA,CACA,2CAAA,CACA,cAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.learnMoreHeader {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 3.6rem;\n}\n\n.learnMoreBtn {\n    display: flex;\n    align-items:center;\n    justify-content: flex-end;\n    margin-top: 3.6rem;\n}\n\n.infoHeader {\n    display: flex; \n    align-items: center; \n    column-gap: 1.6rem;\n    background: rgba(244, 244, 244, 1);\n    padding: 1.6rem;\n    border-radius: 0.8rem;\n    margin-bottom: var(--gutter);\n}\n\n.infoText {\n    font-size: 14px;\n    font-weight: 400;\n}\n\n.infoBtn {\n    font-size: 14px;\n    font-weight: 600;\n    border: none;\n    background: transparent;\n    color: var(--color-primary-alpha) !important;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"learnMoreHeader": `_Vkzmz9N_o6fe03lRvmf`,
	"learnMoreBtn": `nCxlzvOHmpp0lgJ8ey6N`,
	"infoHeader": `gkWI4THhCXfb5i6Jll5M`,
	"infoText": `wOsTbwziCQal1MXpsGJM`,
	"infoBtn": `JuvSrY_uzzubZjdoP30L`
};
export default ___CSS_LOADER_EXPORT___;
