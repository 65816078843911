import React, { useMemo } from 'react';
import { parseTimeLabel, getColorByStatus, parseDayLabel } from '../../../AdherenceCalendar/common';

import Style from './SidebarPeriod.module.scss';
import { DoseDetails } from './SidebarPeriod.types';
import { useCallback } from 'react';
import { LooseObject } from '../../common.types';
import Loader from '../../../../../../ui-kit/graphics/Loader';
import CommonStyle from '../../common.module.scss';
import AlertIcon from '../../../../../../ui-kit/icons/utility/AlertIcon';
import Refill2Icon from '../../../../../../ui-kit/icons/branding/Refill2Icon';
import PillDispenserIcon from '../../../../../../ui-kit/icons/branding/PillDispenserIcon';
import { useAdherenceContext } from '../../../context/AdherenceContext';

interface SidebarDayProps {
    day?: string;
}

const SidebarPeriod: React.FC<SidebarDayProps> = ({ day }) => {
    const { getSidebarPeriod } = useAdherenceContext();

    const [fetch, setFetch] = React.useState<{
        isLoading: boolean;
        data: DoseDetails[] | undefined;
    }>({
        isLoading: false,
        data: undefined
    });

    const { isLoading, data } = fetch;

    React.useEffect(() => {
        setFetch({ isLoading: true, data: undefined });
        getSidebarPeriod({
            datetime_from: day?.split('#')[0] || null,
            datetime_to: day?.split('#')[1] || day?.split('#')[0] || null
        })
            .then((response) => setFetch({ isLoading: false, data: response }))
            .catch(() => setFetch({ isLoading: false, data: undefined }));
    }, [day]);

    const parsedData = useMemo(() => {
        return data?.reduce<LooseObject<DoseDetails[]>>((acc: LooseObject<DoseDetails[]>, dose) => {
            const key = dose.schedule?.dow || dose.scheduled_datetime;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[dose.schedule?.dow || dose.scheduled_datetime].push(dose);
            return acc;
        }, {});
    }, [data]);

    const parsedHeader = useMemo(() => {
        if (!day) return null;
        let parsedDay = day.split('#')[0];
        let date = new Date(parsedDay).toString();
        if (day.split('#')[1]) {
            return date.slice(0, 10);
        }
        return date.slice(0, 10) + ', ' + parseTimeLabel(parsedDay.slice(-8));
    }, [day]);

    const renderLabel = useCallback((dose: DoseDetails) => {
        if (dose.dose_type === 'unscheduled') return 'As-Needed';
        if (dose.status === 'time_to_take') return 'Time to take';
        if (dose.status === 'incoming') return 'Scheduled';
        if (dose.status === 'late') return 'Taken late';
        if (dose.status.includes('_')) return dose.status.replace('_', ' ');
        if (dose.dose_type === 'in_hero') return `${dose.status} (Inside)`;
        if (dose.dose_type === 'outside_hero') return `${dose.status} (Outside)`;
        return dose.status;
    }, []);
    const renderDose = useCallback(
        (dose: DoseDetails, i: number) => {
            return (
                <div key={`sbd-dose-${i}`}>
                    <div
                        className={Style.dose}
                        style={{
                            ...getColorByStatus(dose.status),
                            borderColor:
                                dose.status === 'time_to_take' || dose.status === 'almost_time'
                                    ? 'var(--color-success-beta)'
                                    : 'transparent'
                        }}
                    >
                        {dose.partial && (
                            <AlertIcon
                                size="small"
                                type="inverted"
                                className={[
                                    Style.alert,
                                    getColorByStatus(dose.status).class === 'white'
                                        ? Style.white
                                        : Style.black
                                ].join(' ')}
                            />
                        )}
                        <div className={Style.label}>{renderLabel(dose)}</div>
                        {dose.dose_error === 'cup_not_removed' ? (
                            <div>Dispensed but not removed</div>
                        ) : (
                            <div>
                                {dose.status === 'time_to_take' ? (
                                    <div className={Style.now}>Now!</div>
                                ) : (
                                    parseTimeLabel(dose.reported_datetime?.slice(-8))
                                )}
                            </div>
                        )}
                        {dose.dose_type === 'future_dose' && (
                            <div className={Style.futureDose}>
                                <PillDispenserIcon
                                    size="small"
                                    type="inverted"
                                    className={[
                                        Style.alert,
                                        getColorByStatus(dose.status).class === 'white'
                                            ? Style.white
                                            : Style.black
                                    ].join(' ')}
                                />
                                Future Dispensed on{' '}
                                {`${parseDayLabel(dose.dispensed_datetime)}, ${parseTimeLabel(
                                    dose.dispensed_datetime?.slice(-8)
                                )}`}
                            </div>
                        )}
                    </div>
                    <div className={Style.medRoot}>
                        {dose.medications.map((med, j) => (
                            <React.Fragment key={`sbd-dose-med-${j}`}>
                                <div className={Style.med}>
                                    <div>{med.pill_name}</div>
                                    {dose.dose_type === 'outside_hero' && (
                                        <Refill2Icon
                                            size="small"
                                            type="inverted"
                                            className={Style.alert}
                                        />
                                    )}
                                    {med.pill_error && Number.isInteger(med.actual_pill_qty) && (
                                        <strong>
                                            {Number.isInteger(med.manual_pill_qty)
                                                ? med.actual_pill_qty + med.manual_pill_qty
                                                : med.actual_pill_qty}{' '}
                                            of
                                        </strong>
                                    )}
                                    <strong>{med.scheduled_pill_qty}</strong>
                                </div>
                                {med.pill_error && (
                                    <div className={Style.errorPill}>
                                        {med.pill_error.replace('_', ' ')}
                                    </div>
                                )}
                                {med.pill_error && med.manual_pill_qty > 0 && (
                                    <div className={Style.infoPill}>
                                        {`${med.manual_pill_qty} offered manually`}
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            );
        },
        [renderLabel]
    );
    if (isLoading || !data || !parsedData)
        return (
            <div className={CommonStyle.loader}>
                <Loader />
            </div>
        );
    return (
        <div className={Style.root}>
            <div className={Style.header}>{parsedHeader}</div>
            <div className={Style.content}>
                {Object.keys(parsedData).map((key, i) => {
                    const dataAr = parsedData[key];
                    return (
                        <div className={Style.groupWrapper} key={`sbppd-${i}`}>
                            {' '}
                            <div className={Style.freq}>
                                {dataAr[0]?.schedule &&
                                dataAr[0].schedule.dow?.split(',').length === 7
                                    ? 'Every day'
                                    : dataAr[0].schedule
                                    ? dataAr[0].schedule.dow
                                    : ''}
                            </div>
                            {dataAr.map((dose, j) => renderDose(dose, j))}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SidebarPeriod;
