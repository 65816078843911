// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hpQ6uqLLIL_MCYffLRaR{margin-top:calc(var(--gutter)/2)}.hpQ6uqLLIL_MCYffLRaR>*{flex:auto;padding-left:0 !important;margin-top:0 !important;margin-right:var(--gutter);background:rgba(0,0,0,0) !important}.gTYM14jNq_HxoF1YFpGu label{font-weight:var(--typo-fontweight-strong) !important}.gTYM14jNq_HxoF1YFpGu svg{width:3rem !important;min-width:3rem !important;height:3rem}.SLZUBL3L4DEBTSxze1rD{width:16.5rem}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/PatientDetailsForm/style.module.scss"],"names":[],"mappings":"AACA,sBACI,gCAAA,CACA,wBACI,SAAA,CACA,yBAAA,CACA,uBAAA,CACA,0BAAA,CACA,mCAAA,CAKJ,4BACI,oDAAA,CAGJ,0BACI,qBAAA,CACA,yBAAA,CACA,WAAA,CAIR,sBACI,aAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.radioGroup {\n    margin-top: calc(var(--gutter) / 2);\n    & > * {\n        flex: auto;\n        padding-left: 0 !important;\n        margin-top: 0 !important;\n        margin-right: var(--gutter);\n        background: transparent !important;\n    }\n}\n\n.radio {\n    label {\n        font-weight: var(--typo-fontweight-strong) !important;\n    }\n\n    svg {\n        width: 3rem !important;\n        min-width: 3rem !important;\n        height: 3rem;\n    }\n}\n\n.saveBtn {\n    width: 16.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioGroup": `hpQ6uqLLIL_MCYffLRaR`,
	"radio": `gTYM14jNq_HxoF1YFpGu`,
	"saveBtn": `SLZUBL3L4DEBTSxze1rD`
};
export default ___CSS_LOADER_EXPORT___;
