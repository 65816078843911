import React from 'react';
import { NavLink, useLocation, useParams, useSearchParams } from 'react-router-dom';

import Section from '@hero/ui-kit/layout/Section';
import H from '@hero/ui-kit/typography/H';

import Layout from '../../../../components/Layout';
import ErrorState from '../../../../components/States/Error';
import Loading from '../../../../components/States/Loading';
import useGetOrganizationMemberById from '../../api/useGetOrganizationMemberById';
import { OrganizationMemberDetailsRouteParams } from '../../types';
import OrganizationMemberDetailsProvider, {
    useOrganizationMemberDetailsContext
} from '../context/OrganizationMemberDetailsContext';
import Style from './style.module.scss';
import getMemberUrl from '../../utils/getMemberUrl';
import { showMemberActivityFeature } from '../../../../constants/featureFlags';
import useBack from '../../../../hooks/useBack';
import BackButton from '../../../../components/BackButton';
import UserInfo from './UserInfo';
import MemberDeviceInfo from './MemberDeviceInfo';
import Pill from '../../../LeadsManagement/components/Pill';

const Header: React.FC = () => {
    const { memberDetails, memberName, currentDevice } = useOrganizationMemberDetailsContext();
    const { caregiving, member_info, medication_info } = memberDetails;

    const location = useLocation();

    const state = { from: location.state?.from };

    React.useEffect(() => {
        if (location.pathname.includes('adherence') && location.state?.from) {
            localStorage.setItem('locationFrom', location.state?.from);
        }
    }, [location]);

    const tabs = React.useMemo(() => {
        return [
            {
                label: 'Demographics',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id),
                hasPipe: false,
                show: true
            },
            {
                label: 'Health Assessment',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'medications'),
                hasPipe: false,
                show: !!medication_info?.length
            },
            {
                label: 'Adherence',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'adherence'),
                hasPipe: false,
                show: !!medication_info?.length && currentDevice?.device_status === 'CONFIGURED'
            },
            // {
            //     label: 'Caregiving',
            //     link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'caregiving'),
            //     hasPipe: true,
            //     show: !!caregiving?.length
            // },
            {
                label: 'Activity',
                link: getMemberUrl(member_info.hero_user_id, member_info.member_id, 'activity'),
                hasPipe: false,
                show: showMemberActivityFeature
            }
        ];
    }, [
        caregiving?.length,
        medication_info?.length,
        member_info?.hero_user_id,
        member_info?.member_id,
        currentDevice
    ]);

    const isAdmin = currentDevice?.member_role === 'PRIMARY_USER_ADMIN';

    return (
        <Section noDefaultPadding paddingVertical="small" paddingHorizontal="regular">
            <div className={Style.headerWrapper}>
                <div>
                    <div className={Style.name}>
                        <H role="h5" noDefaultMargin>
                            {memberName}
                        </H>
                        <Pill
                            label={isAdmin ? 'Admin' : 'Read only'}
                            styles={{
                                textTransform: 'none',
                                fontWeight: '700',
                                fontSize: '1.6rem',
                                height: '3.2rem',
                                borderRadius: '1.8rem',
                                background: isAdmin ? 'rgba(255, 229, 214, 1)' : 'transparent',

                                ...(!isAdmin && {
                                    border: '2px solid rgba(231, 231, 231, 1)'
                                })
                            }}
                        />
                    </div>

                    <div className={Style.subhead}>
                        <UserInfo />
                        <div className={Style.nav}>
                            {tabs
                                .filter((tab) => tab.show)
                                .map((tab) => (
                                    <React.Fragment key={tab.label}>
                                        {tab.hasPipe && <div className={Style.pipe} />}
                                        <NavLink end key={tab.label} to={tab.link} state={state}>
                                            {tab.label}
                                        </NavLink>
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                </div>
                <div>
                    <MemberDeviceInfo />
                </div>
            </div>
        </Section>
    );
};

const MemberDetailsWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { id } = useParams<Required<OrganizationMemberDetailsRouteParams>>();

    const back = useBack();

    const [searchParams] = useSearchParams();

    const query = React.useMemo(() => {
        const preparedId = id ? +id : 0;
        if (searchParams.get('role') === 'caregiver') {
            return {
                hero_user_id: preparedId
            };
        }

        return {
            member_id: preparedId
        };
    }, [searchParams, id]);

    const { data, isLoading, isError } = useGetOrganizationMemberById(query);

    return (
        <Layout paddingHorizontal="none">
            {isLoading ? (
                <Loading />
            ) : isError ? (
                <ErrorState />
            ) : (
                <OrganizationMemberDetailsProvider memberDetails={data}>
                    <Section noDefaultPadding paddingHorizontal="regular">
                        <BackButton
                            onBack={() => back(localStorage.getItem('locationFrom') || '/members')}
                            alt="Back to members"
                        />
                    </Section>

                    <Header />

                    {children}
                </OrganizationMemberDetailsProvider>
            )}
        </Layout>
    );
};
export default MemberDetailsWrapper;
