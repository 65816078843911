import Modal from '@hero/ui-kit/components/Modal';
import Button from '@hero/ui-kit/inputs/Button';
import XButton from '@hero/ui-kit/inputs/XButton';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import Style from './style.module.scss';
import InfoIcon from '@hero/ui-kit/icons/utility/InfoIcon';
import * as React from 'react';

type DeviceInfoProps = {
    explanation?: string;
};

const DeviceInfo: React.FC<DeviceInfoProps> = ({
    explanation = 'You will need the admin account and serial number associated with the device to validate their Hero membership.'
}) => {
    const [showLearnMoreModal, setShowLearnMoreModal] = React.useState(false);
    const handleCloseLearnMoreModal = () => {
        setShowLearnMoreModal(false);
    };

    const handleOpenLearnMoreModal = () => {
        setShowLearnMoreModal(true);
    };
    return (
        <>
            <div className={Style.infoHeader}>
                <div style={{ minWidth: '24px' }}>
                    <InfoIcon height={22} width={20} type="utility" />
                </div>

                <div className={Style.infoText}>
                    {explanation}
                    <button
                        className={Style.infoBtn}
                        type="button"
                        onClick={handleOpenLearnMoreModal}
                    >
                        Learn more
                    </button>
                </div>
            </div>
            <Modal
                externalControls={[showLearnMoreModal, setShowLearnMoreModal]}
                styles={{ padding: 0, maxWidth: '57rem' }}
            >
                <Section noDefaultPadding>
                    <div className={Style.learnMoreHeader}>
                        <P noDefaultMargin strong styles={{ fontSize: '2.4rem' }}>
                            Hero member information
                        </P>
                        <XButton onClick={handleCloseLearnMoreModal} alt="Close modal" />
                    </div>
                    <p>
                        Ask the member to provide this information by navigating on Hero dispenser
                        to <strong>{'Main Menu > Device Settings > About > Device info'}</strong>
                    </p>
                    <div className={Style.learnMoreBtn}>
                        <Button
                            onClick={handleCloseLearnMoreModal}
                            type="button"
                            variant="outlineSecondary"
                        >
                            Close
                        </Button>
                    </div>
                </Section>
            </Modal>
        </>
    );
};

export default DeviceInfo;
