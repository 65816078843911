// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wLu_bCYCS8DbkO2J2gAn:hover{background-color:rgba(254,234,203,.5) !important;border-radius:0 !important}.kOaBAq282NLcWjnZN8Am{display:flex;align-items:center}.zSrKRaWwFN5due2AlPwP{outline:none !important}.iknPVXZf2C6aocT0aP7y{color:var(--color-primary-alpha);font-size:1.6rem;line-height:2rem;font-weight:600;background-color:rgba(0,0,0,0);border:none;cursor:pointer}`, "",{"version":3,"sources":["webpack://./pages/UsersManagement/UserList/style.module.scss"],"names":[],"mappings":"AAGI,4BACI,gDAAA,CACA,0BAAA,CAIR,sBACI,YAAA,CACA,kBAAA,CAGJ,sBACI,uBAAA,CAGJ,sBACI,gCAAA,CACA,gBAAA,CACA,gBAAA,CACA,eAAA,CACA,8BAAA,CACA,WAAA,CACA,cAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n\n.userItem {\n    &:hover {\n        background-color: rgba(254, 234, 203, 0.5) !important;\n        border-radius: 0 !important;\n    }\n}\n\n.nameWrapper {\n    display: flex;\n    align-items: center;\n}\n\n.rowBtn {\n    outline: none !important;\n}\n\n.filterClearBtn {\n    color: var(--color-primary-alpha);\n    font-size: 1.6rem;\n    line-height: 2rem;\n    font-weight: 600;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userItem": `wLu_bCYCS8DbkO2J2gAn`,
	"nameWrapper": `kOaBAq282NLcWjnZN8Am`,
	"rowBtn": `zSrKRaWwFN5due2AlPwP`,
	"filterClearBtn": `iknPVXZf2C6aocT0aP7y`
};
export default ___CSS_LOADER_EXPORT___;
