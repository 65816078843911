// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CsX3YI3yr7L4lPl0_R8Z{margin-right:var(--gutter)}.LezM9S9KoGx7RMjiML4V{position:fixed;top:0;left:0;right:0;bottom:0;z-index:10;background:var(--color-dark-overlay)}.aWBUMEOU9P81MnDEIYQq{position:fixed;left:0;top:0;bottom:0;width:24rem;background:var(--color-neutrals-background);padding:calc(var(--gutter)*2) var(--gutter)}.aWBUMEOU9P81MnDEIYQq ul{list-style:none;padding:0;margin-top:calc(var(--gutter)*2)}.aWBUMEOU9P81MnDEIYQq ul li{margin-top:var(--gutter)}.aWBUMEOU9P81MnDEIYQq .active{color:var(--color-primary-alpha)}.nnAIN6a16UCCXt0nBvBi{display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./components/Layout/MenuNavbar/style.module.scss"],"names":[],"mappings":"AACA,sBACI,0BAAA,CAGJ,sBACI,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,QAAA,CACA,UAAA,CACA,oCAAA,CAGJ,sBACI,cAAA,CACA,MAAA,CACA,KAAA,CACA,QAAA,CACA,WAAA,CACA,2CAAA,CACA,2CAAA,CAEA,yBACI,eAAA,CACA,SAAA,CACA,gCAAA,CAEA,4BACI,wBAAA,CAIR,8BACI,gCAAA,CAIR,sBACI,YAAA,CACA,kBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.menuIcon {\n    margin-right: var(--gutter);\n}\n\n.overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 10;\n    background: var(--color-dark-overlay);\n}\n\n.menu {\n    position: fixed;\n    left: 0;\n    top: 0;\n    bottom: 0;\n    width: 24rem;\n    background: var(--color-neutrals-background);\n    padding: calc(var(--gutter) * 2) var(--gutter);\n\n    ul {\n        list-style: none;\n        padding: 0;\n        margin-top: calc(var(--gutter) * 2);\n\n        li {\n            margin-top: var(--gutter);\n        }\n    }\n\n    :global(.active) {\n        color: var(--color-primary-alpha);\n    }\n}\n\n.menuLogo {\n    display: flex;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuIcon": `CsX3YI3yr7L4lPl0_R8Z`,
	"overlay": `LezM9S9KoGx7RMjiML4V`,
	"menu": `aWBUMEOU9P81MnDEIYQq`,
	"menuLogo": `nnAIN6a16UCCXt0nBvBi`
};
export default ___CSS_LOADER_EXPORT___;
