import React from 'react';
import { escapeRegExp } from './getHasHighlightedText';
import Style from './Highlight.module.scss';

interface HighlightedTextProps {
    styles?: {};
    className?: string;
    text?: string;
    highlight?: string;
}

const HighlightedText: React.FC<HighlightedTextProps> = ({
    styles = {},
    className = Style.highlighted,
    text = '',
    highlight = ''
}) => {
    const highlightedText = React.useMemo(() => {
        const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

        return parts.map((part, index) => (
            <React.Fragment key={index}>
                {part.toLowerCase() === highlight.toLowerCase() ? (
                    <span style={styles} className={className}>
                        {part}
                    </span>
                ) : (
                    part
                )}
            </React.Fragment>
        ));
    }, [text, highlight, styles, className]);

    return <>{highlightedText}</>;
};

export default HighlightedText;
