import React from 'react';
import AlertIcon from '../../../../../ui-kit/icons/utility/AlertIcon';
import PillDispenserIcon from '../../../../../ui-kit/icons/branding/PillDispenserIcon';
import { parseTimeLabel, getColorByStatus } from '../common';

import Style from './DosePills.module.scss';
import { IDose } from '../common.types';

interface DosePillsProps {
    doses: IDose[][];
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, time: string) => void;
    selected?: boolean;
}

const DosePills: React.FC<DosePillsProps> = ({ doses, onClick, selected }) => {
    const renderLabel = (dose: IDose) => {
        return (
            <div>
                {dose.status === 'on_time'
                    ? dose.dose_type === 'future_dose'
                        ? 'Taken'
                        : parseTimeLabel(dose.reported_datetime.slice(-8))
                    : dose.status === 'late'
                    ? dose.dose_type === 'future_dose'
                        ? 'Late'
                        : `${parseTimeLabel(dose.reported_datetime.slice(-8))} (Late)`
                    : dose.status === 'incoming'
                    ? 'Scheduled'
                    : dose.status === 'time_to_take'
                    ? 'Time to take'
                    : dose.status === 'almost_time'
                    ? 'Almost time'
                    : dose.status}
            </div>
        );
    };
    const renderDose = React.useCallback(
        (dose: IDose, index: number) => {
            return (
                <React.Fragment key={`pill=${index}`}>
                    {index > 0 && <div className={Style.separator} />}

                    <div
                        role="button"
                        className={Style.pill}
                        style={{
                            ...getColorByStatus(dose.status),
                            borderColor:
                                dose.status === 'almost_time' || dose.status === 'time_to_take'
                                    ? 'var(--color-success-beta)'
                                    : 'transparent'
                        }}
                        onClick={(e) => onClick(e, dose.scheduled_datetime)}
                        onKeyDown={() => {}}
                        tabIndex={0}
                    >
                        <>
                            <div>
                                {dose.medication_count} {dose.medication_count > 1 ? 'meds' : 'med'}
                                {dose.dose_type === 'in_hero'
                                    ? ' (Inside)'
                                    : dose.dose_type === 'outside_hero'
                                    ? ' (Outside)'
                                    : ''}
                            </div>
                            {dose.dose_type !== 'unscheduled' && renderLabel(dose)}
                        </>

                        {dose.partial && (
                            <AlertIcon
                                size="small"
                                type="inverted"
                                className={[
                                    Style.alert,
                                    getColorByStatus(dose.status).class === 'white'
                                        ? Style.white
                                        : Style.black
                                ].join(' ')}
                            />
                        )}
                        {dose.dose_type === 'future_dose' && (
                            <PillDispenserIcon
                                size="small"
                                type="inverted"
                                className={[
                                    Style.alert,
                                    getColorByStatus(dose.status).class === 'white'
                                        ? Style.white
                                        : Style.black
                                ].join(' ')}
                            />
                        )}
                    </div>
                </React.Fragment>
            );
        },
        [onClick]
    );

    return (
        <div className={[Style.root, selected ? Style.selected : ''].join(' ')}>
            {doses.map((doseAr, ind) => {
                return (
                    <div className={Style.dosePills} key={`dose-pills-${ind}`}>
                        {doseAr.map((dose, index) => renderDose(dose, index))}
                    </div>
                );
            })}
        </div>
    );
};

export default DosePills;
