import React from 'react';
import PillLink from '../../../../components/PillLink';
import getMemberUrl from '../../utils/getMemberUrl';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import DotsMoreIcon from '@hero/ui-kit/icons/utility/DotsMoreIcon';
import HighlightedText from '../../../../components/HighlightedText';
import { CaregiverInfo } from '../../types';
import getMemberRoleLabel from '../utils/getMemberRoleLabel';
import getHasHighlightedText from '../../../../components/HighlightedText/getHasHighlightedText';
import Style from './style.module.scss';

const getCaregiverName = (caregiver?: CaregiverInfo) => {
    if (caregiver?.first_name && caregiver.last_name) {
        return `${caregiver.first_name} ${caregiver.last_name} - ${getMemberRoleLabel(
            caregiver.member_role
        )}`;
    }
    return '';
};

interface CaregiversProps {
    caregivers?: CaregiverInfo[];
    search: string;
}

const Caregivers: React.FC<CaregiversProps> = ({ caregivers, search }) => {
    const mainCaregiver = React.useMemo(() => {
        const adminCaregiver = caregivers?.find(
            (caregiver) => caregiver.member_role === 'CAREGIVER_ADMIN'
        );
        const readOnlyCaregiver = caregivers?.find(
            (caregiver) => caregiver.member_role === 'CAREGIVER_READ_ONLY'
        );

        return adminCaregiver || readOnlyCaregiver;
    }, [caregivers]);

    const restCaregivers = React.useMemo(() => {
        return caregivers?.filter(
            (caregiver) => caregiver?.hero_user_id !== mainCaregiver?.hero_user_id
        );
    }, [caregivers, mainCaregiver]);

    const [expand, setExpand] = React.useState(false);

    const handleExpand = React.useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        setExpand((prevState) => !prevState);
    }, []);

    const highlightedCaregiverName = React.useMemo(() => {
        const caregiverName = getCaregiverName(mainCaregiver);
        return <HighlightedText highlight={search} text={caregiverName} />;
    }, [search, mainCaregiver]);

    const hasSomeHighlightedRestCaregiverNames = React.useMemo(() => {
        if (restCaregivers?.length) {
            let check = false;
            restCaregivers.forEach(
                (caregiver) => (check = getHasHighlightedText(getCaregiverName(caregiver), search))
            );

            return check;
        }

        return false;
    }, [search, restCaregivers]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'start',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {mainCaregiver ? (
                    <PillLink
                        to={getMemberUrl(mainCaregiver?.hero_user_id, mainCaregiver?.member_id)}
                        variant={
                            mainCaregiver?.member_role === 'CAREGIVER_ADMIN'
                                ? 'primary'
                                : 'secondary'
                        }
                        styles={{ height: '3.2rem' }}
                    >
                        {highlightedCaregiverName}
                    </PillLink>
                ) : null}
                {restCaregivers?.length ? (
                    <ClickableOpacity
                        onClick={handleExpand}
                        alt="show-more"
                        className={`${Style.moreBtn} ${
                            hasSomeHighlightedRestCaregiverNames ? Style.highlighted : ''
                        }`}
                    >
                        <DotsMoreIcon width={20} height={20} styles={{ marginTop: '0.4rem' }} />
                    </ClickableOpacity>
                ) : null}
            </div>
            {expand
                ? restCaregivers?.map((caregiver, index) => (
                      <React.Fragment
                          key={`${index}-${caregiver.hero_user_id}-${caregiver.member_id}`}
                      >
                          <div></div>
                          <PillLink
                              to={getMemberUrl(caregiver?.hero_user_id, caregiver?.member_id)}
                              variant={
                                  caregiver?.member_role === 'CAREGIVER_ADMIN'
                                      ? 'primary'
                                      : 'secondary'
                              }
                              styles={{ height: '3.2rem', marginTop: '0.5rem' }}
                          >
                              <HighlightedText
                                  text={getCaregiverName(caregiver)}
                                  highlight={search}
                              />
                          </PillLink>
                          <div></div>
                      </React.Fragment>
                  ))
                : null}
        </div>
    );
};

export default Caregivers;
