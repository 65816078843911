// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QhzDq0YZ8OYNVMqTKwCn{border-bottom:none !important;color:var(--color-primary-alpha) !important}`, "",{"version":3,"sources":["webpack://./components/Link/style.module.scss"],"names":[],"mappings":"AACA,sBACI,6BAAA,CACA,2CAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.link {\n    border-bottom: none !important;\n    color: var(--color-primary-alpha) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `QhzDq0YZ8OYNVMqTKwCn`
};
export default ___CSS_LOADER_EXPORT___;
