import P from '@hero/ui-kit/typography/P';
import React from 'react';
import Style from './style.module.scss';
import PlusIcon from '@hero/ui-kit/icons/utility/PlusIcon';
import getApprovalStatus from '../utils/getApprovalStatus';
import NewActivityForm from '../components/NewActivityForm';
import Button from '@hero/ui-kit/inputs/Button';
import {
    ApprovalStatus,
    LeadActivityDetail,
    LeadDetailsResponse,
    LeadStateResponse
} from '../types';
import Modal from '@hero/ui-kit/components/Modal';
import H from '@hero/ui-kit/typography/H';
import MedicareLeadForm from '../components/EnrolmentForm';
import LeadActivity from './LeadActivity';
import { useLeadDetailsContext } from './v2/context/LeadDetailsContext';
import { format } from 'date-fns';
import RefreshIcon from '../../../icons/RefreshIcon';
import ExpiredLeadActivity from './ExpiredLeadActivity';
import Section from '@hero/ui-kit/layout/Section';

const StatusDivider: React.FC<{ status: string }> = ({ status }) => {
    return (
        <span className={Style.statusDivider}>
            <h2>
                <span>{status}</span>
            </h2>
        </span>
    );
};

interface LeadActivitiesProps {
    lead?: LeadDetailsResponse;
    leadState?: LeadStateResponse;
    isLocked?: boolean;
    refetchLeadDetails?: () => void;
    refetchLeadState?: () => void;
    leadId: string;
    canEdit?: boolean;
}

const LeadActivities: React.FC<LeadActivitiesProps> = ({
    lead,
    leadId,
    isLocked = false,
    refetchLeadDetails,
    refetchLeadState,
    leadState,
    canEdit = true
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [showEnrollModal, setShowEnrollModal] = React.useState(false);
    const [showConfirmReactivateModal, setShowConfirmReactivateModal] = React.useState(false);
    const { onBlockNext } = useLeadDetailsContext();

    const handleClick = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleCloseEnrollModal = () => {
        setShowEnrollModal(false);
    };

    const handleOpenEnrollModal = () => {
        setShowEnrollModal(true);
    };

    const handleCloseConfirmReactivateModal = () => {
        setShowConfirmReactivateModal(false);
    };

    const handleOpenConfirmReactivateModal = () => {
        setShowConfirmReactivateModal(true);
    };

    const groupedActivities = React.useMemo(() => {
        return lead?.activities_details
            ?.sort(function (a, b) {
                const aValue = new Date(a.updated_at).valueOf();
                const bValue = new Date(b.updated_at).valueOf();
                return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
            })
            ?.reduce<Record<string, LeadActivityDetail[]>>((group, activity) => {
                const status = activity.status.toUpperCase();
                const date = format(new Date(activity.updated_at), 'yyyy-MM-dd HH');
                const key = `${status}-${date}`;
                group[key] = group[key] ?? [];
                group[key].push(activity);

                return group;
            }, {});
    }, [lead?.activities_details]);

    const stageBtn = React.useMemo(() => {
        const isAlreadyReactivated =
            ['REJECTED', 'EXPIRED'].includes(lead?.status?.toUpperCase() || '') &&
            lead?.primary_user_details.reactivated_date;

        if (
            !canEdit ||
            ['ENROLLED'].includes(lead?.sub_status?.toUpperCase() || '') ||
            isAlreadyReactivated
        ) {
            return <div />;
        }

        if (
            ['REJECTED', 'EXPIRED'].includes(lead?.status?.toUpperCase() || '') &&
            !lead?.primary_user_details.reactivated_date
        ) {
            return (
                <Button
                    variant="outlineSecondary"
                    styles={{ minHeight: '4rem' }}
                    onClick={handleOpenConfirmReactivateModal}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            fontSize: '1.6rem'
                        }}
                    >
                        <RefreshIcon size="utility" type="utility" width={20} height={20} />
                        <strong>Reactivate</strong>
                    </div>
                </Button>
            );
        }

        if (lead?.status?.toUpperCase() === 'APPROVED') {
            return (
                <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                    <Button
                        onClick={handleClick}
                        styles={{
                            height: '4rem',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 2rem'
                        }}
                        disabled={isLocked}
                        variant="outlineSecondary"
                    >
                        Reject
                    </Button>
                    <Button
                        styles={{
                            height: '4rem',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 2rem'
                        }}
                        onClick={handleOpenEnrollModal}
                        disabled={isLocked}
                    >
                        Enroll
                    </Button>
                </div>
            );
        }

        return (
            <Button
                onClick={handleClick}
                styles={{
                    height: '4rem',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 2rem'
                }}
                disabled={isLocked}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                        fontSize: '1.6rem'
                    }}
                >
                    <PlusIcon size="utility" width={20} height={20} type="inverted" />
                    <strong>New</strong>
                </div>
            </Button>
        );
    }, [lead, isLocked]);

    const handleActivitySuccess = () => {
        refetchLeadDetails && refetchLeadDetails();
        refetchLeadState && refetchLeadState();
        handleModalClose();
        handleCloseEnrollModal();
        onBlockNext();
    };

    const handleLeadEnrollSuccess = () => {
        handleModalClose();
        handleCloseEnrollModal();
        refetchLeadDetails && refetchLeadDetails();
    };

    const handleSuccessEnrollModal = () => {
        refetchLeadDetails && refetchLeadDetails();
        refetchLeadState && refetchLeadState();
    };

    return (
        <>
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '1.2rem'
                    }}
                >
                    <P noDefaultMargin strong styles={{ fontSize: '2.4rem' }}>
                        Activities
                    </P>
                    {stageBtn}
                </div>
                {groupedActivities
                    ? Object.entries(groupedActivities).map((groupedActivity, index) => {
                          const groupActivities = groupedActivity[1] || [];
                          const statusActivity = groupedActivity[0];
                          const statusApproval = statusActivity.split('-')[0];

                          const nextIndex = index + 1;

                          const previousActivityStatus = Object.entries(groupedActivities)[
                              nextIndex
                          ]
                              ? Object.entries(groupedActivities)[nextIndex][0]
                              : '';

                          const previousStatus = previousActivityStatus.split('-')[0];

                          return (
                              <React.Fragment key={`${statusActivity}-${index}`}>
                                  <StatusDivider
                                      status={
                                          getApprovalStatus(statusApproval as ApprovalStatus).label
                                      }
                                  />
                                  {groupActivities.map((activity, index) => {
                                      return activity.status.toLowerCase() === 'expired' ? (
                                          <ExpiredLeadActivity
                                              key={`${activity.status}-${activity.sub_status}-${index}`}
                                              activityDetails={activity}
                                              isExpiredCurrentStatus={
                                                  lead?.status?.toUpperCase() === 'EXPIRED'
                                              }
                                          />
                                      ) : (
                                          <LeadActivity
                                              key={`${activity.status}-${activity.sub_status}-${index}`}
                                              activityDetails={activity}
                                              isReactivated={
                                                  previousStatus === 'REJECTED' &&
                                                  index === groupActivities.length - 1
                                              }
                                          />
                                      );
                                  })}
                              </React.Fragment>
                          );
                      })
                    : null}
            </>

            {showModal ? (
                <NewActivityForm
                    leadName={`${lead?.primary_user_details.first_name} ${lead?.primary_user_details.last_name}`}
                    handleModalClose={handleModalClose}
                    state={leadState}
                    leadId={leadId}
                    onActivitySuccess={handleActivitySuccess}
                />
            ) : null}

            <Modal
                externalControls={[showEnrollModal, setShowEnrollModal]}
                isCancelable
                onClose={handleCloseEnrollModal}
                styles={{ padding: '0' }}
            >
                <H role="h5">Enroll Lead</H>
                <MedicareLeadForm
                    leadId={leadId}
                    firstName={
                        lead?.primary_user_details.first_name || lead?.contact_details.first_name
                    }
                    lastName={
                        lead?.primary_user_details.last_name || lead?.contact_details.last_name
                    }
                    isCaregiver={!!lead?.contact_details.first_name}
                    email={lead?.primary_user_details.email}
                    address_line_1={lead?.address_details.street_address}
                    address_line_2={lead?.address_details.apartment}
                    state={lead?.address_details.state}
                    city={lead?.address_details.city}
                    zip_code={lead?.address_details.shipping_zip}
                    phone={lead?.primary_user_details.phone}
                    isMember={lead?.is_existing_member}
                    admin_email={lead?.primary_user_details.admin_email}
                    external_serial={lead?.primary_user_details.external_serial}
                    external_user_id={lead?.primary_user_details.external_user_id}
                    formVersion={lead?.form_version}
                    onSuccess={handleLeadEnrollSuccess}
                    onSuccessModal={handleSuccessEnrollModal}
                />
            </Modal>
            <Modal
                externalControls={[showConfirmReactivateModal, setShowConfirmReactivateModal]}
                isCancelable
                onClose={handleCloseConfirmReactivateModal}
                styles={{ padding: '0', width: '60rem' }}
            >
                <>
                    <P
                        strong
                        centered
                        noDefaultMargin
                        styles={{ marginBottom: '2.4rem', fontSize: '3.2rem', marginTop: '2rem' }}
                    >
                        Reactivate application
                    </P>
                    <P centered noDefaultMargin styles={{ marginBottom: '3.6rem' }}>
                        <strong>Enrollment process must be completed within 28 days</strong>,<br />{' '}
                        or application expires permanently.
                    </P>
                    <Section centered noDefaultPadding>
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '2.4rem' }}>
                            <Button
                                variant="outlineSecondary"
                                onClick={handleCloseConfirmReactivateModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    setShowConfirmReactivateModal(false);
                                    handleClick();
                                }}
                            >
                                Continue
                            </Button>
                        </div>
                    </Section>
                </>
            </Modal>
        </>
    );
};

export default LeadActivities;
