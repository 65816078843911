import { ApprovalStatus } from '../types';
import Style from './style.module.scss';

const NOT_FOUND = {
    label: 'Not Found',
    className: ''
};

const getApprovalStatus = (value?: ApprovalStatus) => {
    const statuses = {
        INITIAL_INTEREST: {
            label: 'Initial interest',
            className: Style.NEW_APPLICATION
        },
        INSURANCE_VALIDATION: {
            label: 'Insurance validation',
            className: Style.PENDING_MANUAL_VOB
        },
        QUALIFIED: {
            label: 'Qualified',
            className: Style.LEAD_QUALIFIED
        },
        MEDICAL_CHECK: {
            label: 'Medical check',
            className: Style.PENDING_MEDICAL_NECESSITY
        },
        APPROVED: {
            label: 'Approved',
            className: Style.APPROVED
        },
        REJECTED: {
            label: 'Rejected',
            className: Style.REJECTED
        },
        EXPIRED: {
            label: 'Expired',
            className: Style.EXPIRED
        }
    };

    return value && statuses[value] ? statuses[value] : NOT_FOUND;
};

export default getApprovalStatus;
