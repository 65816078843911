// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lc9iWc34IX0UWF1ZRdiW{display:flex;align-items:center;justify-content:space-between}.mAFGICo1_uXXnlgO6Aet{display:flex;align-items:center;column-gap:1.4rem}.kIFb1dFARhPIxGQossKV{width:100%;border-radius:2rem;margin-bottom:2rem}.ZaD_DhGJOv_aKk49H8rl{margin-bottom:3.6rem !important;height:160px}.mDmHK2aAGVbNsSyhgAkg{margin-top:2.4rem;margin-bottom:3.6rem}.YoZdvH8VafhZne1h1Lnf{margin-top:3.6rem;display:flex;align-items:center;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/PatientActivityDetailsModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,iBAAA,CAGJ,sBACI,UAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACI,+BAAA,CACA,YAAA,CAGJ,sBACI,iBAAA,CACA,oBAAA,CAGJ,sBACI,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.flexBetween {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.flexAlign {\n    display: flex;\n    align-items: center;\n    column-gap: 1.4rem;\n}\n\n.videoStyle {\n    width: 100%;\n    border-radius: 2rem;\n    margin-bottom: 2rem;\n}\n\n.textarea {\n    margin-bottom: 3.6rem !important;\n    height: 160px;\n}\n\n.textSection {\n    margin-top: 2.4rem;\n    margin-bottom: 3.6rem;\n}\n\n.cancelBtn {\n    margin-top: 3.6rem;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexBetween": `lc9iWc34IX0UWF1ZRdiW`,
	"flexAlign": `mAFGICo1_uXXnlgO6Aet`,
	"videoStyle": `kIFb1dFARhPIxGQossKV`,
	"textarea": `ZaD_DhGJOv_aKk49H8rl`,
	"textSection": `mDmHK2aAGVbNsSyhgAkg`,
	"cancelBtn": `YoZdvH8VafhZne1h1Lnf`
};
export default ___CSS_LOADER_EXPORT___;
