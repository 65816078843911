import Input from '@hero/ui-kit/inputs/Input';
import Container from '@hero/ui-kit/layout/Container';
import Section from '@hero/ui-kit/layout/Section';
import Style from './style.module.scss';
import React from 'react';
import P from '@hero/ui-kit/typography/P';
import { useFormContext, useWatch } from '@hero/ui-kit/inputs/Form';
import RadioGroup from '@hero/ui-kit/inputs/RadioGroup';
import Radio from '@hero/ui-kit/inputs/Radio';

interface PatientDetailsFormProps {
    isLoading?: boolean;
    canEdit?: boolean;
}

const FormElements: React.FC<{
    isSaveDisabled?: boolean;
    canEdit?: boolean;
}> = ({ canEdit }) => {
    const disabled = !canEdit;

    const {
        setValue,
        formState: { isDirty }
    } = useFormContext();

    React.useEffect(() => {
        setValue('isDirty', isDirty);
    }, [setValue, isDirty]);

    const insuranceType = useWatch({ name: 'insurance_type' });

    return (
        <>
            <Section noDefaultPadding>
                <div style={{ width: '100%', textAlign: 'left' }}>
                    <P
                        noDefaultMargin
                        strong
                        styles={{ fontSize: '2.4rem', marginBottom: '3.6rem' }}
                    >
                        Insurance Type
                    </P>
                </div>
                <RadioGroup
                    className={Style.radioGroup}
                    name="insurance_type"
                    displayName=""
                    noBorders
                    disabled={disabled}
                >
                    <Radio
                        id="original_medicare"
                        value="ORIGINAL_MEDICARE"
                        labelText="Original Medicare"
                        className={Style.radio}
                    />
                    {insuranceType === 'ORIGINAL_MEDICARE' ? (
                        <Section noDefaultPadding>
                            <Input
                                disabled={disabled}
                                name="insurance_id"
                                displayName="Member ID"
                            />
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input
                                    disabled={disabled}
                                    name="medigap_insurance_policy_id"
                                    displayName="Medigap Member ID"
                                />
                                <Input
                                    disabled={disabled}
                                    name="medigap_insurance_name"
                                    displayName="Medigap Insurance Company Name"
                                />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                    <Radio
                        id="medicare_advantage"
                        value="MEDICARE_ADVANTAGE"
                        labelText="Medicare Advantage"
                        className={Style.radio}
                    />
                    {insuranceType === 'MEDICARE_ADVANTAGE' ? (
                        <Section noDefaultPadding>
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input
                                    disabled={disabled}
                                    name="insurance_id"
                                    displayName="Member ID"
                                />
                                <Input
                                    disabled={disabled}
                                    name="insurance_company"
                                    displayName="Insurance Company"
                                />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                    <Radio
                        id="not_sure"
                        value="NOT_SURE"
                        labelText="I'm not sure"
                        className={Style.radio}
                    />
                    {insuranceType === 'NOT_SURE' ? (
                        <Section noDefaultPadding>
                            <Container gridTemplateColumns="1fr 1fr" gridColumnGap="regular">
                                <Input
                                    disabled={disabled}
                                    name="insurance_id"
                                    displayName="Member ID"
                                />
                                <Input
                                    disabled={disabled}
                                    name="insurance_company"
                                    displayName="Insurance Company"
                                />
                            </Container>
                        </Section>
                    ) : (
                        <Radio id="none-1" value="" labelText="" styles={{ display: 'none' }} />
                    )}
                </RadioGroup>
            </Section>
        </>
    );
};

const InsuranceForm: React.FC<PatientDetailsFormProps> = ({ isLoading, canEdit = true }) => {
    return <FormElements isSaveDisabled={isLoading} canEdit={canEdit} />;
};

export default InsuranceForm;
