// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p8nUp0cEfoeA422t0zYc{position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);font-size:6.4rem;width:200px;height:200px;z-index:100;display:flex;align-items:center;justify-content:center}.kRgATY5ChUFZox2hZboL{position:fixed;top:calc(50% - 148px);left:calc(50% - 120px);width:240px;color:#fff;font-size:2.4rem;font-weight:bold}.jkl0q4WTmzC64icgdV4T{color:#fff}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/Countdown/style.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,uCAAA,CACA,oCAAA,CACA,kCAAA,CACA,mCAAA,CAEA,gBAAA,CACA,WAAA,CACA,YAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,cAAA,CACA,qBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,gBAAA,CACA,gBAAA,CAGF,sBACE,UAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.centered {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    -webkit-transform: translate(-50%, -50%);\n    -moz-transform: translate(-50%, -50%);\n    -o-transform: translate(-50%, -50%);\n    -ms-transform: translate(-50%, -50%);\n    \n    font-size: 6.4rem;\n    width: 200px;\n    height: 200px;\n    z-index: 100;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .text {\n    position: fixed;\n    top: calc(50% - 148px);\n    left: calc(50% - 120px);\n    width: 240px;\n    color: rgba(255, 255, 255, 1);\n    font-size: 2.4rem;\n    font-weight: bold;\n  }\n\n  .number {\n    color: rgba(255, 255, 255, 1);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centered": `p8nUp0cEfoeA422t0zYc`,
	"text": `kRgATY5ChUFZox2hZboL`,
	"number": `jkl0q4WTmzC64icgdV4T`
};
export default ___CSS_LOADER_EXPORT___;
