import React from 'react';
import Styles from './style.module.scss';

interface VideoPreviewProps {
    className?: string;
    videoUrl?: string;
    isUploading?: boolean;
}

const VideoPreview: React.FC<VideoPreviewProps> = ({
    videoUrl,
    className,
    isUploading = false
}) => {
    return videoUrl ? (
        <div className={Styles.videoWrapper}>
            {isUploading ? (
                <div className={Styles.completingWrapper} aria-hidden="true">
                    <div className={Styles.completing}>Completing...</div>
                </div>
            ) : (
                <>
                    {/* eslint-disable */}
                    <video className={className} controls>
                        <source src={videoUrl} />
                        Your browser does not support the video tag.
                    </video>
                </>
            )}
        </div>
    ) : null;
};

export default VideoPreview;
