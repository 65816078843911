import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import Logo from '@hero/branding/logo';
import { useAuthContext } from '@hero/hero-auth/v2/AuthProvider';
import MenuIcon from '@hero/ui-kit/icons/utility/MenuIcon';
import Button from '@hero/ui-kit/inputs/Button';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import XButton from '@hero/ui-kit/inputs/XButton';

import Style from './style.module.scss';
import useGetUserMenuLinks from '../../../routes/useGetUserMenuLinks';

const MenuNavbar: React.FC<{ hideLogo?: boolean }> = ({ hideLogo = false }) => {
    const { logout } = useAuthContext();
    const [showMenu, setShowMenu] = React.useState(false);
    const appLinks = useGetUserMenuLinks();

    const toggleShowMenu = React.useCallback(() => {
        setShowMenu((prevState) => !prevState);
    }, []);

    const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
        if (e.key === 'Escape') {
            setShowMenu(false);
        }
    }, []);

    const links = React.useMemo(() => {
        return appLinks.filter((link) => link.show);
    }, [appLinks]);

    return (
        <>
            <div className={Style.menuLogo}>
                <ClickableOpacity
                    onClick={toggleShowMenu}
                    alt="Expand navigation menu"
                    onKeyDown={handleKeyDown}
                >
                    <MenuIcon className={Style.menuIcon} width={20} />
                </ClickableOpacity>
                {!hideLogo ? (
                    <Link to="/">
                        <Logo height="3.2rem" width="10.8rem" />
                    </Link>
                ) : null}
            </div>

            {showMenu && (
                <div className={Style.overlay}>
                    <div className={Style.menu}>
                        <XButton alt="close" onClick={toggleShowMenu} />
                        <nav>
                            <ul>
                                {links.map((link, index) => (
                                    <li key={`${index}-${link.label}`}>
                                        <NavLink to={link.to}>{link.label}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>

                        <Button styles={{ padding: 0 }} variant="secondary" onClick={logout}>
                            Logout
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default MenuNavbar;
