// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GDDMPfeRrF_RkahAfa0s{position:relative;margin-bottom:3.6rem;width:100%}.r0jiYnmLCmcJqJNRQM4J{display:flex;align-items:center;column-gap:.8rem;margin-top:4.8rem;justify-content:center}.TxGcTLURnegIdz8Wga0h{position:absolute;top:0;right:0;cursor:pointer}.n8Lq5tme0jDSQNx4xPIc{margin-top:3.6rem;display:flex;align-items:center;justify-content:flex-end;column-gap:1.2rem}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/LeadErrorModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,iBAAA,CACA,oBAAA,CACA,UAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,iBAAA,CACA,sBAAA,CAGJ,sBACI,iBAAA,CACA,KAAA,CACA,OAAA,CACA,cAAA,CAGJ,sBACI,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.header {\n    position: relative;\n    margin-bottom: 3.6rem;\n    width: 100%;\n}\n\n.headerText {\n    display: flex;\n    align-items: center;\n    column-gap: 0.8rem;\n    margin-top: 4.8rem;\n    justify-content: center;\n}\n\n.xBtn {\n    position: absolute;\n    top: 0;\n    right: 0;\n    cursor: pointer;\n}\n\n.btnWrapper {\n    margin-top: 3.6rem;\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    column-gap: 1.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `GDDMPfeRrF_RkahAfa0s`,
	"headerText": `r0jiYnmLCmcJqJNRQM4J`,
	"xBtn": `TxGcTLURnegIdz8Wga0h`,
	"btnWrapper": `n8Lq5tme0jDSQNx4xPIc`
};
export default ___CSS_LOADER_EXPORT___;
