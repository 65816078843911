import React from 'react';
import Style from './style.module.scss';

interface ButtonTogglerProps {
    checked?: boolean;
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
}

const ButtonToggler: React.FC<ButtonTogglerProps> = ({ checked, onChange, disabled }) => {
    return (
        <label className={`${Style.switch} ${disabled ? Style.disabled : ''}`}>
            <input
                type="checkbox"
                className={`${Style.input}`}
                onChange={(event) => {
                    onChange?.(event.target.checked);
                }}
                checked={checked}
                disabled={disabled}
            />
            <span className={`${Style.slider} ${Style.round}`}></span>
        </label>
    );
};

export default ButtonToggler;
