// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PJPv_BX5qOXFqB39zqYj{display:flex;align-items:center;justify-content:flex-end;margin-top:3.6rem}.pXMDTL0zpT39ssyfa2Q3{display:flex;align-items:center;justify-content:center}.QqBypDOk0mJjUqx3Ry0A{display:flex;align-items:center;justify-content:center;height:7rem;width:7rem;border-radius:100%;border:4px solid orange}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/InactivityModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,UAAA,CACA,kBAAA,CACA,uBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.btnWrapper {\n    display: flex;\n    align-items:center;\n    justify-content: flex-end;\n    margin-top: 3.6rem;\n}\n\n.timerWrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.timer {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 7rem;\n    width: 7rem;\n    border-radius: 100%;\n    border: 4px solid orange;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnWrapper": `PJPv_BX5qOXFqB39zqYj`,
	"timerWrapper": `pXMDTL0zpT39ssyfa2Q3`,
	"timer": `QqBypDOk0mJjUqx3Ry0A`
};
export default ___CSS_LOADER_EXPORT___;
