// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CWDOFo0InGUsEMCsQt9g{position:fixed;top:0;right:0;bottom:0;left:0;height:100%;width:100%;background:#000;opacity:.5;cursor:default}`, "",{"version":3,"sources":["webpack://./components/Overlay/style.module.scss"],"names":[],"mappings":"AACA,sBACI,cAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CACA,UAAA,CACA,cAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.overlay {\n    position: fixed;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    background: black;\n    opacity: 0.5;\n    cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `CWDOFo0InGUsEMCsQt9g`
};
export default ___CSS_LOADER_EXPORT___;
