import React, { useCallback } from 'react';
import Table from '../../components/Table';
import Section from '@hero/ui-kit/layout/Section';
import Container from '@hero/ui-kit/layout/Container';
import Style from './style.module.scss';
import DownloadIcon from '@hero/ui-kit/icons/utility/DownloadIcon';
import { ReportData } from './types';
import CircularButton from '@hero/ui-kit/inputs/CircularButton';
import useDownloadReport from './api/useDownloadReport';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import CheckboxSquare from '@hero/ui-kit/graphics/CheckboxSquare';
import downloadFile from '../../utils/downloadFile';
import { format } from 'date-fns';

const Report: React.FC<{
    report: ReportData;
    isChecked?: boolean;
    onReportCheck?: (reportName: string) => void;
}> = ({ report, isChecked = false, onReportCheck }) => {
    const [reportNameDownload, setReportNameDownload] = React.useState('');
    const { data } = useDownloadReport(
        { report_names: reportNameDownload },
        reportNameDownload.length > 0
    );

    React.useEffect(() => {
        if (reportNameDownload.length && data?.length && data[0] && data[0].report_url) {
            downloadFile(data[0].report_url, data[0].report_name);
            setReportNameDownload('');
        }
    }, [data, reportNameDownload]);

    const handleClick = () => {
        onReportCheck && onReportCheck(report.name);
    };

    const handleDownloadButton = useCallback(() => {
        report?.name && setReportNameDownload(report.name);
    }, [report]);
    return (
        <div
            onKeyDown={() => null}
            tabIndex={0}
            role="button"
            className={Style.itemTopLevelWrapper}
        >
            <Section noDefaultPadding className={`${Style.itemTopLevel}`}>
                <Container
                    gridTemplateColumns={'0.1fr 1fr 1fr 1fr 1fr 1fr'}
                    className={Style.itemTopLevelContainer}
                >
                    <div className={Style.checkboxItemWrapper}>
                        <ClickableOpacity onClick={handleClick} alt={`Check ${report.name}`}>
                            <CheckboxSquare
                                isChecked={isChecked}
                                isHovered={false}
                                isFocused={false}
                                disabled={false}
                                styles={{ height: '3rem', width: '3rem' }}
                            />
                        </ClickableOpacity>
                    </div>
                    <div>{report?.report_to ? format(new Date(report.report_to), 'PP') : '-'}</div>
                    <div>
                        {report?.created_at ? format(new Date(report.created_at), 'PP') : '-'}
                    </div>
                    <div>
                        {' '}
                        <strong>{report?.full_name || '-'}</strong>
                    </div>

                    <div>{report?.external_user_id || '-'}</div>
                    <div style={{ textAlign: 'right' }}>
                        <CircularButton
                            variant="secondary"
                            isLink={false}
                            onClick={handleDownloadButton}
                            marginLeft="small"
                        >
                            <DownloadIcon size="utility" id={report.name} />
                        </CircularButton>
                    </div>
                </Container>
            </Section>
        </div>
    );
};

interface ReportsTableProps {
    reports?: ReportData[];
    isLoading?: boolean;
    onReportCheck?: (reportName: string) => void;
    reportsChecked?: string[];
    pagerData?: { total_count?: number; limit?: number; offset?: number };
}

const ReportsTable: React.FC<ReportsTableProps> = ({
    reports,
    isLoading = false,
    reportsChecked = [],
    onReportCheck,
    pagerData
}) => {
    return (
        <Table
            headerGridTemplateColumns="0.1fr 1fr 1fr 1fr 1fr 1fr"
            headerColumns={[
                <div style={{ paddingRight: '1.2rem', width: '4.5rem' }} />,
                'Cycle end',
                'Created',
                'Member',
                'External ID',
                ''
            ]}
            pagerData={pagerData}
            loading={isLoading}
        >
            {reports?.map((item) => (
                <Report
                    key={item.hero_user_id}
                    report={item}
                    isChecked={reportsChecked?.includes(item?.name || '')}
                    onReportCheck={onReportCheck}
                />
            ))}
        </Table>
    );
};

export default ReportsTable;
