// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Exwe4su7Jj84_6Gw4PwB{display:flex;align-items:center;justify-content:flex-end;margin-top:3.6rem}.Z7nG1P7uuNgavWUXFtUb{margin-top:3.6rem;margin-bottom:3.6rem;border-radius:.8rem;background:#f4f4f4;height:3.6rem;display:flex;align-items:center;justify-content:center;column-gap:3.5rem}.OKYTk4333kScIcf7lV2M{background:#c4c4c4;width:.2rem;height:1.6rem}.QXkm38jkNfeKQR2avomw{color:#74a305;font-weight:700;padding-right:2rem}.NvXHHNrCFiNzlBgs22vw{color:#e83f3f;font-weight:700}.KwTu6EKmayl_cf4aUryg{font-weight:700;padding-left:2rem}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/SignOffDetailsModal/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,oBAAA,CACA,mBAAA,CACA,kBAAA,CACA,aAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGJ,sBACI,kBAAA,CACA,WAAA,CACA,aAAA,CAGJ,sBACI,aAAA,CACA,eAAA,CACA,kBAAA,CAGJ,sBACI,aAAA,CACA,eAAA,CAGJ,sBACI,eAAA,CACA,iBAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.btnWrapper {\n    display: flex;\n    align-items:center;\n    justify-content: flex-end;\n    margin-top: 3.6rem;\n}\n\n.summary {\n    margin-top: 3.6rem;\n    margin-bottom: 3.6rem;\n    border-radius: 0.8rem;\n    background: rgba(244, 244, 244, 1);\n    height: 3.6rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    column-gap: 3.5rem;\n}\n\n.divider {\n    background: rgba(196, 196, 196, 1);\n    width: 0.2rem;\n    height: 1.6rem;\n}\n\n.valid {\n    color: rgba(116, 163, 5, 1);\n    font-weight: 700;\n    padding-right: 2rem;\n}\n\n.discarded {\n    color: rgba(232, 63, 63, 1);\n    font-weight: 700;\n}\n\n.duration {\n    font-weight: 700;\n    padding-left: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnWrapper": `Exwe4su7Jj84_6Gw4PwB`,
	"summary": `Z7nG1P7uuNgavWUXFtUb`,
	"divider": `OKYTk4333kScIcf7lV2M`,
	"valid": `QXkm38jkNfeKQR2avomw`,
	"discarded": `NvXHHNrCFiNzlBgs22vw`,
	"duration": `KwTu6EKmayl_cf4aUryg`
};
export default ___CSS_LOADER_EXPORT___;
