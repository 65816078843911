import React from 'react';
import { LeadDetailsResponse } from '../../../../types';
import PatientDetailsForm from '../../components/LeadUpdateForm/PatientDetailsForm';
import Style from '../../style.module.scss';
import InsuranceForm from '../../components/LeadUpdateForm/InsuranceForm';
import ContactPersonForm from '../../components/LeadUpdateForm/ContactPersonForm';
import AddressForm from '../../../../../../components/AddressForm';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import useInViewport from '@hero/react-hooks/useInViewport';
import useUpdateLead from '../../../../api/useUpdateLead';
import {
    LeadUpdateFormSchemaParams,
    leadUpdateFormSchema
} from '../../components/LeadUpdateForm/validator';
import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import LeadErrorModal from '../../../../components/LeadErrorModal';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
import GeneralStickyNavbar from './GeneralStickyNavbar';
import ConsentPDF from '../../../ConsentPDF';
import prepareState from '../../../../../../utils/prepareState';
import UnsavedChangesModal from '../UnsavedChangesModal';
import useUnsavedChangeBlocker from '../../hooks/useUnsavedChangeBlocker';
import { getDOBFormat } from '../../../../../../utils/date';

interface GeneralProps {
    lead?: LeadDetailsResponse;
    onSuccess?: () => void;
    canEdit?: boolean;
}

interface GeneralInnerProps {
    lead?: LeadDetailsResponse;
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    errorType?: string;
    canEdit?: boolean;
}

let timeout: NodeJS.Timeout | undefined = undefined;

export const getIsExistingMemberCheckError = (errorType?: string) => {
    return [
        'serial_not_exist',
        'email_not_exist',
        'field_external_serial_is_mandatory',
        'field_admin_email_is_mandatory',
        'existing_member_missing_mandatory_items'
    ].includes(errorType || '');
};

const getDefaultValues = (lead: LeadDetailsResponse) => {
    return {
        address_line_1: lead?.address_details?.street_address || '',
        address_line_2: lead?.address_details?.apartment || '',
        city: lead?.address_details?.city || '',
        state: lead?.address_details?.state || '',
        zip: lead?.address_details?.shipping_zip || '',
        insurance_type: lead?.primary_user_details?.insurance_type?.toUpperCase() || '',
        zip_code: lead?.primary_user_details?.zip || '',
        date_of_birth: getDOBFormat(lead.primary_user_details.date_of_birth),
        first_name: lead?.primary_user_details?.first_name || '',
        last_name: lead?.primary_user_details?.last_name || '',
        patient_email: lead?.primary_user_details?.email || '',
        patient_phone: lead?.primary_user_details?.phone || '',
        is_member: lead?.is_existing_member || false,
        admin_email: lead?.primary_user_details.admin_email || '',
        external_serial: lead?.primary_user_details.external_serial || '',
        insurance_id: lead?.primary_user_details?.insurance_id || '',
        insurance_company: lead?.primary_user_details?.insurance_company || '',
        caregiver_first_name: lead?.contact_details.first_name || '',
        caregiver_last_name: lead?.contact_details.last_name || '',
        email: lead?.contact_details.email || '',
        phone: lead?.contact_details.phone || '',
        medigap_insurance_name: lead?.primary_user_details.medigap_insurance_name || '',
        medigap_insurance_policy_id: lead?.primary_user_details.medigap_insurance_policy_id || '',
        external_user_id: lead?.primary_user_details.external_user_id || ''
    };
};

const getLeadUpdateError = (errorType?: string) => {
    const errors: Record<string, string> = {
        serial_not_exist: "Device serial doesn't exists",
        email_not_exist: "Email doesn't exists"
    };

    return errorType && errors[errorType]
        ? errors[errorType]
        : 'Something went wrong. Please contact customer support.';
};

const getUpdateLeadPayload = (attributes: LeadUpdateFormSchemaParams) => {
    const {
        admin_email,
        external_serial,
        is_member,
        insurance_id,
        insurance_company,
        insurance_type,
        medigap_insurance_policy_id,
        medigap_insurance_name,
        date_of_birth,
        zip_code
    } = attributes;

    const formattedDateOfBirth = date_of_birth ? date_of_birth.replace(/-/g, '/') : undefined;

    return {
        first_name: attributes.first_name || undefined,
        last_name: attributes.last_name || undefined,
        phone: formatPhoneNumber(attributes.patient_phone),
        email: attributes.patient_email,
        insurance_type,
        caregiver_first_name: attributes.caregiver_first_name || undefined,
        caregiver_last_name: attributes.caregiver_last_name || undefined,
        caregiver_email: attributes.email || undefined,
        caregiver_phone: attributes.phone ? formatPhoneNumber(attributes.phone) : undefined,
        ...(zip_code && { zip: zip_code }),
        ...(formattedDateOfBirth && {
            date_of_birth: getDOBFormat(formattedDateOfBirth, 'yyyy-MM-dd')
        }),
        is_member,
        ...(is_member && admin_email && { admin_email }),
        ...(is_member && external_serial && { external_serial }),
        ...(insurance_type !== 'ORIGINAL_MEDICARE' &&
            insurance_company && {
                insurance_carrier_by_user: insurance_company
            }),
        ...(insurance_id && { insurance_policy_id: insurance_id }),
        ...(insurance_type === 'ORIGINAL_MEDICARE' &&
            medigap_insurance_name && { medigap_insurance_name }),
        ...(insurance_type === 'ORIGINAL_MEDICARE' &&
            medigap_insurance_policy_id && { medigap_insurance_policy_id }),
        external_user_id: attributes.external_user_id || undefined,
        street_address: attributes.address_line_1 || undefined,
        apartment: attributes?.address_line_2 || undefined,
        state: attributes.state ? prepareState(attributes.state) : undefined,
        city: attributes.city || undefined,
        shipping_zip: attributes.zip || undefined
    };
};

const GeneralInner: React.FC<GeneralInnerProps> = ({
    lead,
    isLoading = false,
    errorType,
    isSuccess = false,
    isError = false,
    canEdit = true
}) => {
    const [menuItem, setMenuItem] = React.useState('patient');
    const [viewportItem, setViewportItem] = React.useState('patient');
    const [showBlockerModal, setShowBlockerModal] = React.useState(false);
    const patientElement = React.useRef<HTMLDivElement>(null);
    const insuranceElement = React.useRef<HTMLDivElement>(null);
    const contactElement = React.useRef<HTMLDivElement>(null);
    const shippingElement = React.useRef<HTMLDivElement>(null);
    const attachmentElement = React.useRef<HTMLDivElement>(null);
    const generalHeaderRef = React.useRef<HTMLDivElement>(null);
    const verticalOffset = '-40%';
    const horizontalOffset = '0%';

    const isPatientElementInViewPort = useInViewport(
        patientElement,
        `${verticalOffset} ${horizontalOffset}`
    );
    const isInsuranceElementInViewPort = useInViewport(
        insuranceElement,
        `${verticalOffset} ${horizontalOffset}`
    );
    const isContactElementInViewPort = useInViewport(
        contactElement,
        `${verticalOffset} ${horizontalOffset}`
    );
    const isShippingElementInViewPort = useInViewport(
        shippingElement,
        `${verticalOffset} ${horizontalOffset}`
    );
    const isAttachmentElementInViewPort = useInViewport(
        attachmentElement,
        `${verticalOffset} ${horizontalOffset}`
    );

    const handleMenuClick = (value: string) => {
        setMenuItem(value);
        setTimeout(() => {
            document.getElementById(value)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
    };

    React.useEffect(() => {
        clearInterval(timeout);
        timeout = setTimeout(() => {
            setMenuItem(viewportItem);
        }, 350);

        return () => {
            clearInterval(timeout);
        };
    }, [viewportItem]);

    React.useEffect(() => {
        isPatientElementInViewPort && setViewportItem('patient');
        isInsuranceElementInViewPort && setViewportItem('insurance');
        isContactElementInViewPort && setViewportItem('contact');
        isShippingElementInViewPort && setViewportItem('shipping');
        isAttachmentElementInViewPort && setViewportItem('attachment');
    }, [
        isPatientElementInViewPort,
        isInsuranceElementInViewPort,
        isContactElementInViewPort,
        isShippingElementInViewPort,
        isAttachmentElementInViewPort
    ]);

    const {
        formState: { dirtyFields },
        reset
    } = useFormContext();

    // Reset formState and it's dirtyFields
    React.useEffect(() => {
        lead && reset(getDefaultValues(lead));
    }, [lead]);

    const isChanged = Object.keys(dirtyFields).length !== 0;

    const { handleCancel, handleDiscard } = useUnsavedChangeBlocker({
        isChanged,
        isSuccess,
        changeBlockerModal: setShowBlockerModal
    });

    const [showStickyNavbar, setShowStickyNavbar] = React.useState(false);

    const handleOnScroll = () => {
        if (generalHeaderRef.current) {
            const top = generalHeaderRef.current.getBoundingClientRect().top;

            setShowStickyNavbar(top < 0);
        }
    };

    React.useEffect(() => {
        document.addEventListener('scroll', handleOnScroll);

        return () => {
            document.removeEventListener('scroll', handleOnScroll);
        };
    }, []);

    const leftMenu = [
        {
            id: 1,
            label: 'Program applicant',
            value: 'patient',
            show: true
        },
        {
            id: 2,
            label: 'Insurance',
            value: 'insurance',
            show: true
        },
        {
            id: 3,
            label: 'Contact person',
            value: 'contact',
            show: true
        },
        {
            id: 4,
            label: 'Shipping address',
            value: 'shipping',
            show: true
        },
        {
            id: 5,
            label: 'Attachments',
            value: 'attachment',
            show: !!lead?.primary_user_details.signed_consent_uploaded
        }
    ];

    return (
        <>
            <div style={{ position: 'relative' }}>
                <GeneralStickyNavbar
                    lead={lead}
                    showStickyNavbar={showStickyNavbar}
                    isLoading={isLoading}
                    isChanged={isChanged}
                />
                <Section noDefaultPadding paddingHorizontal="regular">
                    <div style={{ position: 'sticky', left: 0, top: '86px' }}>
                        <div className={Style.leftMenuWrapper}>
                            {leftMenu
                                .filter((menu) => menu.show)
                                .map((menu) => (
                                    <ClickableOpacity
                                        key={menu.id}
                                        onClick={() => handleMenuClick(menu.value)}
                                        alt={`nav-${menu.label}`}
                                        className={`${Style.leftMenuItem} ${
                                            menu.value === menuItem ? Style.leftMenuActive : ''
                                        }`}
                                    >
                                        {menu.label}
                                    </ClickableOpacity>
                                ))}
                        </div>
                    </div>

                    <div className={Style.headerSection} ref={generalHeaderRef}>
                        <div className={Style.headerInner}>
                            <P noDefaultMargin strong size="large" styles={{ fontSize: '2.4rem' }}>
                                Demographics
                            </P>

                            <Button
                                noDefaultMargin
                                type="submit"
                                styles={{ visibility: isChanged ? 'visible' : 'hidden' }}
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </div>

                    <div
                        ref={patientElement}
                        id="patient"
                        className={`${Style.section} ${Style.patientSection}`}
                    >
                        <PatientDetailsForm canEdit={canEdit} lead={lead} errorType={errorType} />
                    </div>
                    <div
                        ref={insuranceElement}
                        id="insurance"
                        className={`${Style.section} ${Style.insuranceSection}`}
                    >
                        <InsuranceForm canEdit={canEdit} />
                    </div>
                    <div
                        ref={contactElement}
                        id="contact"
                        className={`${Style.section} ${Style.contactSection}`}
                    >
                        <ContactPersonForm canEdit={canEdit} />
                    </div>
                    <div
                        ref={shippingElement}
                        id="shipping"
                        className={`${Style.section} ${Style.shippingSection}`}
                    >
                        <AddressForm canEdit={canEdit} />
                    </div>
                    {lead?.primary_user_details.signed_consent_uploaded ? (
                        <div
                            ref={attachmentElement}
                            id="attachment"
                            className={`${Style.section} ${Style.attachmentSection}`}
                            style={{ marginBottom: isChanged ? '9.6rem' : '16rem' }}
                        >
                            <P
                                size="large"
                                strong
                                noDefaultMargin
                                styles={{ marginBottom: '3.6rem', fontSize: '2.4rem' }}
                            >
                                Attachments
                            </P>
                            <div
                                style={{
                                    width: '340px',
                                    minHeight: '80px'
                                }}
                            >
                                <ConsentPDF lead={lead} />
                            </div>
                        </div>
                    ) : null}
                    {isChanged ? (
                        <section className={`${Style.btnSection}`}>
                            <Button type="submit" disabled={isLoading}>
                                Save
                            </Button>
                        </section>
                    ) : null}
                </Section>
            </div>
            <UnsavedChangesModal
                showBlockerModal={showBlockerModal}
                onCancel={handleCancel}
                onSave={handleCancel}
                saveBtnLabel="Go Back to Edit"
                isLoading={isLoading}
                onDiscard={handleDiscard}
                isError={isError}
                errorMessage={getLeadUpdateError(errorType)}
            />
        </>
    );
};

const General: React.FC<GeneralProps> = ({ lead, onSuccess, canEdit }) => {
    const {
        mutate,
        isPending: isLoading,
        isError,
        error,
        isSuccess,
        reset
    } = useUpdateLead(onSuccess);

    const [formAttributes, setFormAttributes] = React.useState<
        LeadUpdateFormSchemaParams | undefined
    >();

    const [showErrorModal, setShowErrorModal] = React.useState(false);

    const handleSubmit = (attributes: LeadUpdateFormSchemaParams) => {
        reset();
        setFormAttributes(attributes);

        const leadId = lead?.primary_user_details.lead_id;
        const payload = getUpdateLeadPayload(attributes);

        leadId &&
            mutate({
                query: { lead_id: leadId },
                payload
            });
    };

    const handleIgnoreOrgAllowSubmit = () => {
        const leadId = lead?.primary_user_details.lead_id;
        if (formAttributes && leadId) {
            reset();
            const payload = getUpdateLeadPayload(formAttributes);
            setShowErrorModal(false);
            mutate({
                query: { lead_id: leadId },
                payload: {
                    ignore_org_allowed_transfer: true,
                    ...payload
                }
            });
        }
    };

    const handleRemoveDeviceParamsSave = () => {
        const leadId = lead?.primary_user_details.lead_id;
        if (formAttributes && leadId) {
            reset();
            const payload = getUpdateLeadPayload(formAttributes);
            setShowErrorModal(false);
            mutate({
                query: { lead_id: leadId },
                payload: {
                    ...payload,
                    external_serial: undefined,
                    admin_email: undefined,
                    is_member: undefined
                }
            });
        }
    };

    const errorType = error?.errors[0];

    const handleErrorSave = () => {
        reset();
        if (errorType === 'transfer_not_allowed') {
            handleIgnoreOrgAllowSubmit();
        }

        if (getIsExistingMemberCheckError(errorType)) {
            handleRemoveDeviceParamsSave();
        }

        return undefined;
    };

    React.useEffect(() => {
        if (isError) {
            setShowErrorModal(true);
        }
    }, [isError, errorType]);

    return (
        <Form
            validationMode="onTouched"
            submitFn={handleSubmit}
            validationSchema={leadUpdateFormSchema}
            defaultValues={lead && getDefaultValues(lead)}
        >
            <GeneralInner
                canEdit={canEdit}
                lead={lead}
                isLoading={isLoading}
                errorType={errorType}
                isSuccess={isSuccess}
                isError={isError}
            />
            <LeadErrorModal
                externalControls={[showErrorModal, setShowErrorModal]}
                onCancel={() => {
                    reset();
                    setShowErrorModal(false);
                }}
                onSave={handleErrorSave}
                isLoading={isLoading}
                errorType={errorType}
            />
        </Form>
    );
};

export default General;
