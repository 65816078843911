import React from 'react';

import Style from './style.module.scss';
import ClickableOpacity from '@hero/ui-kit/inputs/ClickableOpacity';
import useInViewport from '@hero/react-hooks/useInViewport';

import Form, { useFormContext } from '@hero/ui-kit/inputs/Form';
import { formatPhoneNumber } from '@hero/hero-utils/phone';
import Button from '@hero/ui-kit/inputs/Button';
import P from '@hero/ui-kit/typography/P';
import Section from '@hero/ui-kit/layout/Section';
// import GeneralStickyNavbar from './GeneralStickyNavbar';
import PersonalForm from './Forms/PersonalForm';
import ContactForm from './Forms/ContactForm';
import { MemberUpdateFormSchemaParams, memberUpdateFormSchema } from './validator';
import prepareState from '../../../../utils/prepareState';
import UnsavedChangesModal from '../../../LeadsManagement/Details/v2/components/UnsavedChangesModal';
import { OrganizationMemberDetails } from '../../types';
import useUnsavedChangeBlocker from './useUnsavedChangeBlocker';
import StickyNavbar from './StickyNavbar';
import Caregivers from './components/Caregivers';
import useMemberUpdate from '../../api/useMemberUpdate';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import { getDOBFormat } from '../../../../utils/date';
import { useUserStatusContext } from '../../../../context/UserStatus';

interface GeneralProps {
    member?: OrganizationMemberDetails;
    onSuccess?: () => void;
}

interface GeneralInnerProps {
    member?: OrganizationMemberDetails;
    isLoading?: boolean;
    isSuccess?: boolean;
    isError?: boolean;
    errorType?: string;
}

let timeout: NodeJS.Timeout | undefined = undefined;

const getDefaultValues = (member: OrganizationMemberDetails) => ({
    address_line_1: member?.member_info?.address_info?.address_line_1 || '',
    address_line_2: member?.member_info?.address_info?.address_line_2 || '',
    city: member?.member_info?.address_info?.city || '',
    state: member?.member_info?.address_info?.state || '',
    zip: member?.member_info?.address_info?.zip || '',
    date_of_birth: getDOBFormat(member?.member_info?.contact_info?.date_of_birth),
    first_name: member.member_info.first_name || '',
    last_name: member.member_info.last_name || '',
    email: member.member_info.contact_info.email || '',
    phone: member.member_info.contact_info.phone_number || '',
    external_user_id: member.member_info.external_user_id || ''
});

const getMemberUpdateError = (errorType?: string) => {
    const errors: Record<string, string> = {
        serial_not_exist: "Device serial doesn't exists",
        email_not_exist: "Email doesn't exists"
    };

    return errorType && errors[errorType]
        ? errors[errorType]
        : 'Something went wrong. Please contact customer support.';
};

const getUpdateMemberPayload = (attributes: MemberUpdateFormSchemaParams) => {
    return {
        external_user_id: attributes.external_user_id || undefined,
        contact: {
            first_name: attributes.first_name || undefined,
            last_name: attributes.last_name || undefined,
            date_of_birth: attributes.date_of_birth
                ? getDOBFormat(attributes.date_of_birth, 'yyyy-MM-dd')
                : undefined,
            email: attributes.email || undefined,
            cell_phone: attributes.phone ? formatPhoneNumber(attributes.phone) : undefined
        },
        address: {
            address_line_1: attributes.address_line_1 || undefined,
            address_line_2: attributes?.address_line_2 || undefined,
            state: attributes.state ? prepareState(attributes.state) : undefined,
            city: attributes.city || undefined,
            zip: attributes.zip || undefined
        }
    };
};

const DemographicsInner: React.FC<GeneralInnerProps> = ({
    member,
    isLoading = false,
    errorType,
    isSuccess = false,
    isError = false
}) => {
    const [menuItem, setMenuItem] = React.useState('patient');
    const [viewportItem, setViewportItem] = React.useState('patient');
    const [showBlockerModal, setShowBlockerModal] = React.useState(false);
    const patientElement = React.useRef<HTMLDivElement>(null);
    const caregiversElement = React.useRef<HTMLDivElement>(null);
    const contactElement = React.useRef<HTMLDivElement>(null);
    const generalHeaderRef = React.useRef<HTMLDivElement>(null);
    const verticalOffset = '-40%';
    const horizontalOffset = '0%';

    const { permissions } = useUserStatusContext();

    const canEdit = permissions.leads.leadListEdit;

    const { memberDetails } = useOrganizationMemberDetailsContext();

    const { caregivers } = memberDetails;

    const hasCaregivers = caregivers && caregivers.length > 0;

    const isPatientElementInViewPort = useInViewport(
        patientElement,
        `${verticalOffset} ${horizontalOffset}`
    );
    const isCaregiversElementInViewPort = useInViewport(
        caregiversElement,
        `${verticalOffset} ${horizontalOffset}`
    );
    const isContactElementInViewPort = useInViewport(
        contactElement,
        `${verticalOffset} ${horizontalOffset}`
    );

    const handleMenuClick = (value: string) => {
        setMenuItem(value);
        setTimeout(() => {
            document.getElementById(value)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
    };

    React.useEffect(() => {
        clearInterval(timeout);
        timeout = setTimeout(() => {
            setMenuItem(viewportItem);
        }, 350);

        return () => {
            clearInterval(timeout);
        };
    }, [viewportItem]);

    React.useEffect(() => {
        isPatientElementInViewPort && setViewportItem('patient');
        isCaregiversElementInViewPort && setViewportItem('caregivers');
        isContactElementInViewPort && setViewportItem('contact');
    }, [isPatientElementInViewPort, isCaregiversElementInViewPort, isContactElementInViewPort]);

    const {
        formState: { dirtyFields },
        reset
    } = useFormContext();

    // Reset formState and it's dirtyFields
    React.useEffect(() => {
        member && reset(getDefaultValues(member));
    }, [member]);

    const isChanged = Object.keys(dirtyFields).length !== 0;

    const { handleCancel, handleDiscard } = useUnsavedChangeBlocker({
        isChanged,
        isSuccess,
        changeBlockerModal: setShowBlockerModal
    });

    const [showStickyNavbar, setShowStickyNavbar] = React.useState(false);

    const handleOnScroll = () => {
        if (generalHeaderRef.current) {
            const top = generalHeaderRef.current.getBoundingClientRect().top;

            setShowStickyNavbar(top < 0);
        }
    };

    React.useEffect(() => {
        document.addEventListener('scroll', handleOnScroll);

        return () => {
            document.removeEventListener('scroll', handleOnScroll);
        };
    }, []);

    const leftMenu = [
        {
            id: 1,
            label: 'Personal',
            value: 'patient',
            show: true
        },
        {
            id: 2,
            label: 'Contact',
            value: 'contact',
            show: true
        },
        {
            id: 3,
            label: 'Caregivers',
            value: 'caregivers',
            show: hasCaregivers
        }
    ];

    return (
        <>
            <div style={{ position: 'relative' }}>
                <StickyNavbar
                    member={member}
                    showStickyNavbar={showStickyNavbar}
                    isLoading={isLoading}
                    isChanged={isChanged}
                />
                <Section noDefaultPadding paddingHorizontal="regular">
                    <div style={{ position: 'sticky', left: 0, top: '86px' }}>
                        <div className={Style.leftMenuWrapper}>
                            {leftMenu
                                .filter((menu) => menu.show)
                                .map((menu) => (
                                    <ClickableOpacity
                                        key={menu.id}
                                        onClick={() => handleMenuClick(menu.value)}
                                        alt={`nav-${menu.label}`}
                                        className={`${Style.leftMenuItem} ${
                                            menu.value === menuItem ? Style.leftMenuActive : ''
                                        }`}
                                    >
                                        {menu.label}
                                    </ClickableOpacity>
                                ))}
                        </div>
                    </div>

                    <div className={Style.headerSection} ref={generalHeaderRef}>
                        <div className={Style.headerInner}>
                            <P noDefaultMargin strong size="large" styles={{ fontSize: '24px' }}>
                                Demographics
                            </P>

                            <Button
                                noDefaultMargin
                                type="submit"
                                styles={{ visibility: isChanged ? 'visible' : 'hidden' }}
                                disabled={isLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </div>

                    <div
                        ref={patientElement}
                        id="patient"
                        className={`${Style.section} ${Style.patientSection}`}
                    >
                        <PersonalForm canEdit={canEdit} />
                    </div>
                    <div
                        ref={contactElement}
                        id="contact"
                        className={`${Style.section} ${Style.insuranceSection}`}
                    >
                        <ContactForm canEdit={canEdit} />
                    </div>
                    {hasCaregivers ? (
                        <div
                            ref={caregiversElement}
                            id="caregivers"
                            className={`${Style.section} ${Style.contactSection}`}
                        >
                            <Caregivers />
                        </div>
                    ) : null}

                    {isChanged ? (
                        <section className={`${Style.btnSection}`}>
                            <Button type="submit" disabled={isLoading}>
                                Save
                            </Button>
                        </section>
                    ) : null}
                </Section>
            </div>
            <UnsavedChangesModal
                showBlockerModal={showBlockerModal}
                onCancel={handleCancel}
                onSave={handleCancel}
                saveBtnLabel="Go Back to Edit"
                isLoading={isLoading}
                onDiscard={handleDiscard}
                isError={isError}
                errorMessage={getMemberUpdateError(errorType)}
            />
        </>
    );
};

const Demographics: React.FC<GeneralProps> = ({ member, onSuccess }) => {
    const {
        isPending: isLoading,
        isError,
        error,
        isSuccess,
        reset,
        mutate
    } = useMemberUpdate({ member_id: member?.member_info.member_id }, onSuccess);

    const handleSubmit = (attributes: MemberUpdateFormSchemaParams) => {
        reset();

        const payload = getUpdateMemberPayload(attributes);

        mutate({ payload });
    };

    const errorType = error?.errors[0];

    return (
        <Form
            validationMode="onTouched"
            submitFn={handleSubmit}
            validationSchema={memberUpdateFormSchema}
            defaultValues={member && getDefaultValues(member)}
        >
            <DemographicsInner
                member={member}
                isLoading={isLoading}
                errorType={errorType}
                isSuccess={isSuccess}
                isError={isError}
            />
        </Form>
    );
};

export default Demographics;
