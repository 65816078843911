import React from 'react';

import useIsMinBreakpoint from '@hero/react-hooks/mediaQuery/useIsMinBreakpoint';

import Error from '../../../components/States/Error';
import NoResults from '../../../components/States/NoResults';
import Table from '../../../components/Table';
import DeviceFilter from './filter/DeviceFilter';
import ListItem from './ListItem';
import useListQueryParams from './useListQueryParams';
import { useUserStatusContext } from '../../../context/UserStatus';
import useGetDeviceStatistics from '../api/useGetDeviceStatistics';
import Section from '@hero/ui-kit/layout/Section';
import P from '@hero/ui-kit/typography/P';
import H from '@hero/ui-kit/typography/H';
import MembersSparklingIllustration from '@hero/ui-kit/graphics/Illustrations/MembersSparklingIllustration';
import Link from '../../../components/Link';
import LimitItems from '../../../components/LimitItems';
import { OrganizationMemberListPaginated } from '../types';

const OrganizationMemberList: React.FC<{
    isLoading: boolean;
    isError: boolean;
    data?: OrganizationMemberListPaginated;
}> = ({ isLoading, isError, data }) => {
    const isMinL = useIsMinBreakpoint('l');
    const { permissions } = useUserStatusContext();

    const { query, deviceStatisticsQuery } = useListQueryParams();
    const { limit, offset } = query;
    const search = query?.search || '';
    const hasFilters = !!query.device_status || !!query.is_online;

    React.useEffect(() => {
        return () => {
            localStorage.removeItem('locationFrom');
        };
    }, []);

    const { isLoading: isDeviceStatisticsLoading, data: deviceStatistics } =
        useGetDeviceStatistics(deviceStatisticsQuery);

    if (isError) {
        return <Error />;
    }

    if (data?.list.length === 0 && search.length === 0 && !hasFilters) {
        return (
            <Section centered>
                <H role="h6" styles={{ fontSize: '24px' }}>
                    No members enrolled yet
                </H>
                <P>
                    Check out the latest state of potential members via
                    <Link to={'/leads'}> Lead Management</Link>
                </P>
                <MembersSparklingIllustration
                    styles={{ height: '20rem', width: '64rem', marginTop: '5rem' }}
                />
            </Section>
        );
    }

    if (data?.list.length === 0 && search.length) {
        return <NoResults search={search} />;
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '3rem'
                }}
            >
                <LimitItems
                    limit={limit ? +limit : 10}
                    offset={offset ? +offset : 0}
                    total_count={data?.total_count}
                    isLoading={isLoading}
                />
                <DeviceFilter
                    deviceStatistics={deviceStatistics}
                    isDeviceStatisticsLoading={isDeviceStatisticsLoading}
                />
            </div>

            <Table
                pagerData={data}
                headerColumns={['Member', 'Device', 'Activated on', 'Caregivers', 'Status']}
                headerGridTemplateColumns={isMinL ? '3.4fr 2fr 2fr 3fr 1.6fr' : '1fr'}
                loading={isLoading || isDeviceStatisticsLoading}
                gridColumnGap="small"
            >
                {data?.list.length ? (
                    data?.list.map((item, index) => (
                        <ListItem
                            externalUserId={item.external_user_id}
                            activatedAt={item.activated_at}
                            memberId={item.member_id}
                            heroUserId={item.hero_user_id}
                            key={`${index}-${item.hero_user_id || item.member_id}-${
                                item.email || 'n/a'
                            }`}
                            name={
                                item.first_name || item.last_name
                                    ? `${item.first_name} ${item.last_name}`
                                    : 'N/A'
                            }
                            email={item.email || undefined}
                            deviceInfo={item?.device_info || undefined}
                            exchangeDeviceInfo={item.exchange_device_info || undefined}
                            caregivers={item?.caregivers || []}
                            search={search}
                            role={item.member_role}
                            canOpenDetails={permissions.fleet.fleetMemberView}
                        />
                    ))
                ) : (
                    <Section centered>
                        <P size="large" strong noDefaultMargin>
                            No Results
                        </P>
                    </Section>
                )}
            </Table>
        </>
    );
};

export default OrganizationMemberList;
