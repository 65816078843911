import React from 'react';

import Style from './style.module.scss';

import { getFormattedDate } from '../../../../utils/date';
import { useOrganizationMemberDetailsContext } from '../context/OrganizationMemberDetailsContext';
import P from '@hero/ui-kit/typography/P';
import CalendarIcon from '@hero/ui-kit/icons/utility/CalendarIcon';

interface UserInfoProps {}

const UserInfo: React.FC<UserInfoProps> = () => {
    const { memberDetails, currentDevice } = useOrganizationMemberDetailsContext();

    const activatedDate = currentDevice?.device_statuses?.find(
        (status) => status.device_status === 'ACTIVATED'
    )?.status_timestamp;

    const hasDevice = currentDevice?.device_status;

    const { member_info } = memberDetails;

    const formattedDate = activatedDate
        ? `Activated on ${getFormattedDate(new Date(activatedDate), 'MMM d, yyyy')}`
        : 'Not Activated Yet';

    return (
        <>
            <div className={Style.userInfo}>
                {member_info?.external_user_id ? (
                    <div className={Style.patientId}>
                        <P noDefaultMargin className={Style.heroId}>
                            {`Patient ID: `} <strong>{member_info.external_user_id}</strong>
                        </P>
                    </div>
                ) : null}
                {member_info?.hero_user_id ? (
                    <P noDefaultMargin className={Style.heroId}>
                        {`Hero ID: `} <strong>{member_info?.hero_user_id}</strong>
                    </P>
                ) : null}
                {hasDevice ? (
                    <div className={`${Style.heroId} ${Style.activatedDate}`}>
                        <CalendarIcon
                            size="small"
                            style={{ marginRight: '0.8rem', height: '2.1rem', width: '2.1rem' }}
                        />
                        <div style={{ lineHeight: '2.4rem', fontSize: '1.6rem' }}>
                            {`Activated: `} <strong>{formattedDate}</strong>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default UserInfo;
