// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.npF__nis20eYuRB7jZvl{display:flex;align-items:center;gap:2rem;margin:calc(var(--gutter)/2) 0;padding:0 !important}.PiK4hcKzRCaUx5GFAOT7{display:flex;align-items:center;justify-content:center;width:2.4rem;height:2.4rem;border-radius:100%;border:.2rem solid var(--color-primary-beta)}.RD7fxoroz1Ptkm3SfrF9{box-shadow:-2px 0px 24px 0px rgba(0,0,0,.24) !important;border-left:1px solid #fff !important}.GXkftyqWKSWDULoPyb7Z{height:100%}.GXkftyqWKSWDULoPyb7Z form{height:100%}.kCLay9utTQqLTVCw7Dy_{color:var(--color-fail-alpha) !important}.QSjOvpT8dQs0JezPnqSi{opacity:.5}`, "",{"version":3,"sources":["webpack://./pages/LeadsManagement/components/NewActivityForm/style.module.scss"],"names":[],"mappings":"AACA,sBACI,YAAA,CACA,kBAAA,CACA,QAAA,CACA,8BAAA,CACA,oBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,YAAA,CACA,aAAA,CACA,kBAAA,CACA,4CAAA,CAGJ,sBACI,uDAAA,CACA,qCAAA,CAGJ,sBACI,WAAA,CACA,2BACI,WAAA,CAIR,sBACI,wCAAA,CAGJ,sBACI,UAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.radioItem {\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n    margin: calc(var(--gutter) / 2) 0;\n    padding: 0 !important;\n}\n\n.successOption {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 2.4rem;\n    height: 2.4rem;\n    border-radius: 100%;\n    border: 0.2rem solid var(--color-primary-beta)\n}\n\n.sidebarContent {\n    box-shadow: -2px 0px 24px 0px rgba(0, 0, 0, 0.24) !important;\n    border-left: 1px solid rgba(255, 255, 255, 1) !important;\n}\n\n.formWrapper {\n    height: 100%;\n    form {\n        height: 100%;\n    }\n}\n\n.failText {\n    color: var(--color-fail-alpha) !important;\n}\n\n.disabled {\n    opacity: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioItem": `npF__nis20eYuRB7jZvl`,
	"successOption": `PiK4hcKzRCaUx5GFAOT7`,
	"sidebarContent": `RD7fxoroz1Ptkm3SfrF9`,
	"formWrapper": `GXkftyqWKSWDULoPyb7Z`,
	"failText": `kCLay9utTQqLTVCw7Dy_`,
	"disabled": `QSjOvpT8dQs0JezPnqSi`
};
export default ___CSS_LOADER_EXPORT___;
