import * as React from 'react';
import { Overlay } from '../../../../../../components/Overlay';
import Styles from './style.module.scss';
import CircleCountdown from '../CircleCountdown';

interface CountdownProps {
    onEnd?: () => void;
    seconds?: number;
}

const Countdown: React.FC<CountdownProps> = ({ onEnd, seconds = 3 }) => {
    return (
        <>
            <Overlay />
            <div className={Styles.text}>Recording begins in...</div>
            <div className={Styles.centered}>
                <CircleCountdown
                    seconds={seconds}
                    size={120}
                    strokeBgColor="transparent"
                    strokeColor="#ffffff"
                    strokeWidth={6}
                    fontSize="6.4rem"
                    fontWeight="700"
                    onEnd={onEnd}
                />
            </div>
        </>
    );
};

export default Countdown;
