import React from 'react';
import Styles from './style.module.scss';

type Props = {
    seconds: number;
    size: number;
    strokeBgColor: string;
    strokeColor: string;
    strokeWidth: number;
    fontWeight?: string;
    fontSize?: string;
    fontColor?: string;
    timeSequence?: string;
    onEnd?: () => void;
};

const CircleCountdown: React.FC<Props> = ({
    seconds,
    size,
    strokeBgColor,
    strokeColor,
    strokeWidth,
    fontWeight = 'bold',
    timeSequence = '',
    fontColor,
    fontSize,
    onEnd
}) => {
    const milliseconds = seconds * 1000;
    const radius = size / 2;
    const circumference = size * Math.PI;

    const [isPlaying, setIsPlaying] = React.useState(false);
    const [countdown, setCountdown] = React.useState(milliseconds);

    const strokeDashoffset = () => circumference - (countdown / milliseconds) * circumference;

    React.useEffect(() => {
        setIsPlaying(true);

        const interval = setInterval(() => {
            setCountdown((prevCount) => {
                const newCount = prevCount - 10;
                if (newCount === 0) {
                    clearInterval(interval);
                    setIsPlaying(false);
                    onEnd && onEnd();
                }
                return newCount;
            });
        }, 10);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const countdownSizeStyles = {
        height: size,
        width: size
    };

    const textStyles = {
        color: fontColor || strokeColor,
        fontSize: fontSize || size * 0.3,
        fontWeight
    };

    return (
        <div>
            <div style={countdownSizeStyles} className={Styles.countdownContainer}>
                <p style={textStyles}>{`${(countdown / 1000).toFixed()}${timeSequence}`}</p>
                <svg className={Styles.svg}>
                    <circle
                        cx={radius}
                        cy={radius}
                        r={radius}
                        fill="none"
                        stroke={strokeBgColor}
                        strokeWidth={strokeWidth}
                    ></circle>
                </svg>
                <svg className={Styles.svg}>
                    <circle
                        strokeDasharray={circumference}
                        strokeDashoffset={isPlaying ? strokeDashoffset() : 0}
                        r={radius}
                        cx={radius}
                        cy={radius}
                        fill="none"
                        strokeLinecap="round"
                        stroke={strokeColor}
                        strokeWidth={strokeWidth}
                    ></circle>
                </svg>
            </div>
        </div>
    );
};

export default CircleCountdown;
