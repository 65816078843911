// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PxMOS1TCyV04CBv7j5NQ{background-size:100% 100%;display:flex;align-items:center;justify-content:center;background:#e7e7e7;border-radius:2rem}.MnTRU8IZ_DKePXEHjII4{display:flex;align-items:center;justify-content:center;font-size:1.6rem;color:#fff;font-weight:600}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/PatientActivity/components/VideoPreview/style.module.scss"],"names":[],"mappings":"AACA,sBACI,yBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,UAAA,CACA,eAAA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.completingWrapper {\n    background-size: 100% 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: rgba(231, 231, 231, 1);\n    border-radius: 2rem;\n}\n\n.completing {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 1.6rem;\n    color: rgba(255, 255, 255, 1);\n    font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completingWrapper": `PxMOS1TCyV04CBv7j5NQ`,
	"completing": `MnTRU8IZ_DKePXEHjII4`
};
export default ___CSS_LOADER_EXPORT___;
