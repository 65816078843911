// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qm7Em__P8gMre_bqyWTl:before{content:"•";margin-left:3rem;margin-right:3rem}`, "",{"version":3,"sources":["webpack://./pages/FleetManagement/OrganizationMemberDetails/BasicInfo/style.module.scss"],"names":[],"mappings":"AAAA,6BAEI,WACI,CAAA,gBACA,CAAA,iBACA","sourcesContent":["@import \"src/style/vars-and-mixins.scss\";\n.healthConditions {\n    &:before {\n        content: \"•\";\n        margin-left: 3rem;\n        margin-right: 3rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"healthConditions": `Qm7Em__P8gMre_bqyWTl`
};
export default ___CSS_LOADER_EXPORT___;
